import { ReadonlyInput } from 'components/common/ReadonlyInput';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import usePushManagementCompare from 'lib/hooks/usePushManagementCompare';

export const TabCompare = () => {
  const { pushConfig, isLoading } = usePushManagementCompare();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="p-4">
      {pushConfig.length === 0 && (
        <p className="text-md text-center">
          No changes
        </p>
      )}
      {pushConfig.map(({ type, days, ...config }, index) => {
        return (
          <div className="pb-4 mb-4 border-b border-gray-200" key={index}>
            <p className="text-lg mb-4 px-2">{type}</p>
            <div className="flex">
              <div className="w-1/4">
                {config.liveDayTitle && (
                  <ReadonlyInput
                    label="Push title"
                    value={config.liveDayTitle}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
                {config.liveDayTitle !== config.draftDayTitle && (
                  <ReadonlyInput
                    label=""
                    value={config.draftDayTitle}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
              </div>
              <div className="w-1/2">
                {config.liveDayMessage && (
                  <ReadonlyInput
                    label="Push message"
                    value={config.liveDayMessage}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
                {config.liveDayMessage !== config.liveDayMessage && (
                  <ReadonlyInput
                    label=""
                    value={config.liveDayMessage}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
              </div>
              <div className="w-52">
                {config.liveDeliveryTime && (
                  <ReadonlyInput
                    label="Push time"
                    value={config.liveDeliveryTime}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
                {config.liveDeliveryTime !== config.draftDeliveryTime && (
                  <ReadonlyInput
                    label=""
                    value={config.draftDeliveryTime}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
              </div>
              <div className="w-52">
                {typeof config.liveSpecificDay === 'boolean' && (
                  <ReadonlyInput
                    label="Day Specific"
                    value={config.liveSpecificDay ? 'Yes' : 'No'}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
                {typeof config.draftSpecificDay === 'boolean' &&
                  config.liveSpecificDay !== config.draftSpecificDay && (
                    <ReadonlyInput
                      label=""
                      value={config.draftSpecificDay ? 'Yes' : 'No'}
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                    />
                  )}
              </div>
              <div className="w-52">
                {typeof config.liveActive === 'boolean' && (
                  <ReadonlyInput
                    label="Active"
                    value={config.liveActive ? 'Yes' : 'No'}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                )}
                {!config.draftSpecificDay &&
                  typeof config.draftActive === 'boolean' &&
                  config.liveActive !== config.draftActive && (
                    <ReadonlyInput
                      label=""
                      value={config.draftActive ? 'Yes' : 'No'}
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                    />
                  )}
              </div>
              <div className="w-52" />
            </div>
            <div>
              {days?.map((day) => (
                <div className="flex" key={day.id}>
                  <div className="w-1/4">
                    <ReadonlyInput
                      label={`${day.day?.toUpperCase()} Push title`}
                      value={day.live.specificDayTitle}
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                    />
                    {day.live.specificDayTitle !==
                      day.draft.specificDayTitle && (
                      <ReadonlyInput
                        label=""
                        value={day.draft.specificDayTitle}
                        labelStyle="block text-sm text-gray-400 mb-2"
                        inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                        controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      />
                    )}
                  </div>
                  <div className="w-1/2">
                    <ReadonlyInput
                      label={`${day.day?.toUpperCase()} Push message`}
                      value={day.live.specificDayMessage}
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                    />
                    {day.live.specificDayMessage !==
                      day.draft.specificDayMessage && (
                      <ReadonlyInput
                        label=""
                        value={day.draft.specificDayMessage}
                        labelStyle="block text-sm text-gray-400 mb-2"
                        inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                        controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      />
                    )}
                  </div>
                  <div className="w-52">
                    <ReadonlyInput
                      label="Push time"
                      value={day.live.deliveryTime}
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                    />
                    {day.live.deliveryTime !== day.draft.deliveryTime && (
                      <ReadonlyInput
                        label=""
                        value={day.draft.deliveryTime}
                        labelStyle="block text-sm text-gray-400 mb-2"
                        inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                        controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      />
                    )}
                  </div>
                  <div className="w-52" />
                  <div className="w-52">
                    <ReadonlyInput
                      value={day.live.active ? 'Yes' : 'No'}
                      label="Active"
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                    />
                    {day.live.active !== day.draft.active && (
                      <ReadonlyInput
                        value={day.draft.active ? 'Yes' : 'No'}
                        label=""
                        labelStyle="block text-sm text-gray-400 mb-2"
                        inputStyle="w-full rounded -mt-8 py-2 px-4 block border-gray-200 border-2 bg-green-100"
                        controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      />
                    )}
                  </div>
                  <div className="w-52" />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
