import QRCode from 'react-qr-code';

type CardQrCodeProps = { id: string };

export const CardQrCode = ({ id }: CardQrCodeProps) => {
  return (
    <div className="m-4 min-w-[400px]">
      <p className="pb-4">
        You can use this qr code to preview card on real device
      </p>
      <QRCode value={id.replace('/cards/', '')} />
    </div>
  );
};
