import { CardTypes } from 'lib/types/entities';

export const CARD_TYPES: ReadonlyArray<keyof typeof CardTypes> = [
  'mindfulness',
  'affirmation',
  'gratitude',
  'intention',
  'kudos',
];

export const FREQUENCY_OPTIONS = Array.from({ length: 121 }, (_, i) => i).map(
  (number) => {
    return { value: String(number), label: String(number) };
  },
);
export const SIMILIATITY_OPTIONS = Array.from({ length: 51 }, (_, i) => i).map(
  (number) => {
    return number === 0
      ? { value: '0', label: 'NA' }
      : { value: String(number), label: String(number) };
  },
);
export const CARD_TYPE_OPTIONS = CARD_TYPES.map((type) => {
  return { value: type, label: type.slice(0, 1).toUpperCase() + type.slice(1) };
});
