import { create, StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';
import {
  HistorySlice,
  EntityListSlice,
  UsersSlice,
  UserProfileSlice,
  CardsSlice,
  EntityItem,
  UserProfileShape,
  SignInSteps,
  FAQSlice,
  StaticPagesSlice,
  LoaderSlice,
  MediaFilesSlice,
  FeedbackSlice,
} from '../types/store';
import { HistoryTableData, CardsTableData } from '../types/tables';

const createUserProfileSlice: StateCreator<
  UserProfileSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  UserProfileSlice
> = (set) => ({
  userProfile: null,
  isFirebaseConnected: false,
  updateIsFirebaseConnected: (newData) =>
    set(() => ({ isFirebaseConnected: newData })),
  currentSignInStep: SignInSteps.EMAIL,
  updateCurrentSignInStep: (newStep: SignInSteps) =>
    set(() => ({ currentSignInStep: newStep })),
  updateUserProfile: (newData) => set(() => ({ userProfile: newData })),
  cleanUserProfile: () =>
    set(() => ({
      userProfile: null,
    })),
});
const createLoaderSlice: StateCreator<
  LoaderSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  LoaderSlice
> = (set) => ({
  isFetching: false,
  updateIsFetching: (newData) => set(() => ({ isFetching: newData })),
});

const createStaticPagesSlice: StateCreator<
  StaticPagesSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  StaticPagesSlice
> = (set) => ({
  contentPages: [],
  updateContentPages: (newData) => set(() => ({ contentPages: newData })),
});

const createFAQSlice: StateCreator<
  FAQSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  FAQSlice
> = (set) => ({
  faqContent:
    '<h1 class="ql-align-center"><strong style="font-size: 48pt;">You have questions </strong></h1><h1 class="ql-align-center"><strong style="font-size: 48pt;">We have answers</strong></h1><p class="ql-align-center"><br></p><p class="ql-align-center"><br></p><p>Lorem ipsum dolor sit amet consectetur. Consequat dignissim molestie lorem phasellus viverra ullamcorper proin. Euismod viverra ut viverra bibendum. Mi pretium auctor lacus commodo. Imperdiet nisi massa quam diam volutpat. Nisl nisl nullam feugiat velit adipiscing at amet. Pharetra purus consequat diam ut vulputate. Mi nisl velit metus tincidunt quis libero morbi.</p><p>Faucibus vel nisl suspendisse nisl. At quis auctor sem volutpat nascetur consequat sodales duis dignissim. Elementum quis duis vitae mattis pharetra egestas feugiat.</p>',
  faqQuestions: [],
  updateFaqQuestions: (newData) => set(() => ({ faqQuestions: newData })),
  updateFaqContent: (newData) => set(() => ({ faqContent: newData })),
});

const createUsersSlice: StateCreator<
  UsersSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  UsersSlice
> = (set) => ({
  usersTableData: [],
  updateUsersTableData: (newData: UserProfileShape[]) =>
    set(() => ({ usersTableData: newData })),
  cleanUsersTableData: () =>
    set(() => ({
      usersTableData: [],
    })),
});

const createMediaFilesSlice: StateCreator<
  MediaFilesSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  MediaFilesSlice
> = (set) => ({
  mediaFilesTableData: [],
  updateMediaFilesTableData: (newData: any[]) =>
    set(() => ({ mediaFilesTableData: newData })),
  cleanMediaFilesTableData: () =>
    set(() => ({
      mediaFilesTableData: [],
    })),
});

const creatFeedbackSlice: StateCreator<
  FeedbackSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  FeedbackSlice
> = (set) => ({
  feedbackTableData: [],
  updateFeedbackTableData: (newData) =>
    set(() => ({ feedbackTableData: newData })),
  cleanFeedbackTableData: () =>
    set(() => ({
      feedbackTableData: [],
    })),
});

const createCardsSlice: StateCreator<
  CardsSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  CardsSlice
> = (set) => ({
  cardsTableData: [],
  updateCardsTableData: (newData: CardsTableData) =>
    set(() => ({ cardsTableData: newData })),
  cleanCardsTableData: () =>
    set(() => ({
      cardsTableData: [],
    })),
});

const createHistorySlice: StateCreator<
  HistorySlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  HistorySlice
> = (set) => ({
  historyTableData: [],
  updateHistoryTableData: (newData: HistoryTableData) =>
    set(() => ({ historyTableData: newData }), false, 'updateHistoryTableData'),
  cleanHistoryTableData: () =>
    set(
      () => ({
        historyTableData: [],
      }),
      false,
      'cleanHistoryTableData',
    ),
});

const createEntityListSlice: StateCreator<
  EntityListSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  EntityListSlice
> = (set, get) => ({
  // List for mocked entities. Remove when backend implement them.
  roleList: [
    { name: 'Admin', id: '1' },
    { name: 'Manager', id: '2' },
    { name: 'User', id: '3' },
  ],
  locationList: [],
  subscriptionTypeList: [
    { name: 'Annual', id: '1' },
    { name: 'Monthly', id: '2' },
  ],
  subscriptionStatusList: [
    { name: 'Lead', id: '1' },
    { name: 'Free trial', id: '2' },
    { name: 'Paid', id: '3' },
  ],
  payStatusList: [
    { name: 'N/A', id: '1' },
    { name: 'Active', id: '2' },
    { name: 'Canceled', id: '3' },
    { name: 'Lapsed', id: '4' },
  ],
  cardStatusList: [
    { name: 'Done', id: '1' },
    { name: 'Undone', id: '2' },
  ],
  subscriptionList: [
    { name: 'Annual', id: '1' },
    { name: 'Monthly', id: '2' },
  ],
  signupOriginList: [
    { name: 'Email', id: '1' },
    { name: 'Facebook', id: '2' },
    { name: 'Apple', id: '3' },
    { name: 'Google', id: '4' },
  ],
  getRoleById: (value: string) =>
    get().roleList.find((role: EntityItem) => role.id === value)?.name || null,
  getSubscriptionStatusById: (value: string) =>
    get().subscriptionStatusList.find(
      (subsciption: EntityItem) => subsciption.id === value,
    )?.name || null,
  getSubscriptionTypeById: (value: string) =>
    get().subscriptionTypeList.find(
      (subsciption: EntityItem) => subsciption.id === value,
    )?.name || null,
  getPayStatusById: (value: string) =>
    get().payStatusList.find((payStatus: EntityItem) => payStatus.id === value)
      ?.name || null,
  getSignupOriginById: (value: string) =>
    get().signupOriginList.find(
      (signUpOrigin: EntityItem) => signUpOrigin.id === value,
    )?.name || null,
  getCardStatusById: (value: string) =>
    get().cardStatusList.find(
      (cardStatus: EntityItem) => cardStatus.id === value,
    )?.name || null,
  updateLocationList: (newData: EntityItem[]) =>
    set(() => ({ locationList: newData }), false, 'updateLocationList'),
});

export const useStore = create<
  UsersSlice &
    CardsSlice &
    EntityListSlice &
    HistorySlice &
    UserProfileSlice &
    FAQSlice &
    StaticPagesSlice &
    MediaFilesSlice &
    LoaderSlice &
    FeedbackSlice
>()(
  devtools(
    immer((...args) => ({
      ...createUserProfileSlice(...args),
      ...createUsersSlice(...args),
      ...createCardsSlice(...args),
      ...createEntityListSlice(...args),
      ...createHistorySlice(...args),
      ...createFAQSlice(...args),
      ...createStaticPagesSlice(...args),
      ...createLoaderSlice(...args),
      ...createMediaFilesSlice(...args),
      ...creatFeedbackSlice(...args),
    })),
  ),
);
