import { useEffect, useState } from 'react';
import {
  PushConfigDay,
  pushConfigService,
} from 'lib/services/pushConfigService';
import { showToast } from '../toast';

const { getPushConfigRequest } = pushConfigService();

export const usePushManagementLive = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pushConfig, setPushConfig] = useState<ReadonlyArray<PushConfigDay>>(
    [],
  );

  const getCurrentNotifications = async () => {
    setIsLoading(true);

    try {
      const pushConfigResponse = await getPushConfigRequest();

      setPushConfig(pushConfigResponse.data['hydra:member']);
    } catch (e) {
      showToast({
        type: 'error',
        title: `Error`,
        message: 'Cannot access config',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCurrentNotifications();
  }, []);

  return {
    pushConfig,
    isLoading,
  };
};

export default usePushManagementLive;
