import { CustomSelect } from 'components/common/CustomSelect/CustomSelect';
import { PostCardRequestData } from 'lib/services/cardsServices';
import { CardPromptTypes } from 'lib/types/entities';
import {
  Control,
  FormState,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import {
  CARD_TYPE_OPTIONS,
  FREQUENCY_OPTIONS,
  SIMILIATITY_OPTIONS,
} from './cardOptions';

export type CardFormShape = PostCardRequestData;
type CreateTabFromProps = {
  register: UseFormRegister<CardFormShape>;
  formState: FormState<CardFormShape>;
  updateValue: (
    fieldName: keyof CardFormShape,
    value: string | boolean | number,
  ) => void;
  watch: UseFormWatch<CardFormShape>;
  control: Control<CardFormShape>;
  isCreating: boolean;
  isUsed?: boolean;
};

export const CardForm: React.FC<CreateTabFromProps> = ({
  register,
  formState,
  updateValue,
  watch,
  control,
  isUsed = false,
  isCreating = false,
}) => {
  const isAdvanced = watch('advanced');
  const isLimitActive =
    watch('responseType') && watch('responseType') === 'text';

  return (
    <form className="w-[800px]">
      <div className="flex mb-2">
        <div className="flex flex-col mr-5 space-y-2 w-1/2">
          <div className="w-full">
            <label className="text-sm text-gray-400" htmlFor="email">
              Tab title
            </label>
            <input
              type="text"
              placeholder="Title"
              {...register('title')}
              className="w-full rounded p-2 pb-2.5 selected:bg-white border-gray-200 border-2 disabled:text-gray-400 disabled:bg-gray-100"
            />
            {formState?.errors.title?.message && (
              <p className="text-red-500 italic text-xs">
                {formState?.errors.title?.message}
              </p>
            )}
          </div>
          <CustomSelect
            title={
              !isCreating
                ? 'Prompt Media'
                : 'Prompt Media (could be changed after tab creation)'
            }
            name="mediaType"
            isDisabled={isCreating}
            control={control}
            options={[
              { value: CardPromptTypes.text, label: 'Text' },
              { value: CardPromptTypes.audio_text, label: 'Text & Audio' },
            ]}
            error={formState?.errors.mediaType?.message}
          />
          <CustomSelect
            title="Status"
            name="active"
            control={control}
            options={[
              { value: true, label: 'Active' },
              { value: false, label: 'Inactive' },
            ]}
            onCustomChange={(value: string) => {
              updateValue('active', !!value);
            }}
            error={formState?.errors.active?.message}
          />
          <div className="w-full">
            <label htmlFor="advanced" className="text-sm text-gray-400">
              Advanced?
            </label>
            <div className="flex mb-3">
              <div
                onClick={() => updateValue('advanced', true)}
                className="h-11 border border-gray-300 px-2 py-1 rounded w-full mr-2 flex items-center justify-between"
              >
                <p>Yes</p>
                <input
                  type="checkbox"
                  name="isAdvanced"
                  onChange={() => {}}
                  checked={isAdvanced}
                />
              </div>
              <div
                onClick={() => updateValue('advanced', false)}
                className="h-11 border border-gray-300 px-2 py-1 rounded w-full flex items-center justify-between"
              >
                <p>No</p>
                <input
                  type="checkbox"
                  name="notAdvanced"
                  onChange={() => {}}
                  checked={!isAdvanced}
                />
              </div>
            </div>
            {isLimitActive && (
              <div className="w-full relative mb-1">
                <label
                  className="text-sm text-gray-400"
                  htmlFor="responseLimitNum"
                >
                  Response Limit
                </label>
                <div className="w-full flex justify-between space-x-2">
                  <input
                    id="responseLimitNum"
                    type="number"
                    min={0}
                    placeholder="100"
                    {...register('responseLimitNum')}
                    className="w-1/2 rounded p-2 selected:bg-white border-gray-200 border-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none disabled:text-gray-400 disabled:bg-gray-100"
                  />
                  <select
                    id="responseLimitType"
                    {...register('responseLimitType')}
                    className="w-1/2 rounded px-2 py-2.5 bg-white border-gray-200 border-2 disabled:text-gray-400 disabled:bg-gray-100"
                  >
                    <option value="word">Words</option>
                    <option value="character">Characters</option>
                  </select>
                </div>
                {formState?.errors.responseLimitNum?.message && (
                  <p className="text-red-500 italic text-xs absolute">
                    {formState?.errors.responseLimitNum?.message}
                  </p>
                )}
                {formState?.errors.responseLimitType?.message && (
                  <p className="text-red-500 italic text-xs absolute">
                    {formState?.errors.responseLimitType?.message}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-2 w-1/2">
          <CustomSelect
            title="Card type"
            name="type"
            isDisabled={isUsed}
            control={control}
            options={CARD_TYPE_OPTIONS}
            error={formState?.errors.type?.message}
          />
          <CustomSelect
            title="Response Media"
            name="responseType"
            control={control}
            isDisabled={isUsed}
            options={[
              { value: 'text', label: 'Text' },
              { value: 'photo', label: 'Photo' },
            ]}
            error={formState?.errors.responseType?.message}
          />
          <CustomSelect
            title="Frequency"
            name="frequency"
            control={control}
            options={FREQUENCY_OPTIONS}
            error={formState?.errors.frequency?.message}
          />
          <CustomSelect
            title="Similarity group"
            name="similiatityGroup"
            control={control}
            options={SIMILIATITY_OPTIONS}
            error={formState?.errors.similiatityGroup?.message}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="w-full">
          <label className="text-sm text-gray-400" htmlFor="promtTitle">
            Prompt
          </label>
          <input
            type="text"
            placeholder="How do you feel today?"
            {...register('promptTitle')}
            className="w-full rounded p-2 selected:bg-white border-gray-200 border-2 disabled:text-gray-400 disabled:bg-gray-100"
          />
          {formState?.errors.promptTitle?.message && (
            <p className="text-red-500 italic text-xs">
              {formState?.errors.promptTitle?.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="text-sm text-gray-400" htmlFor="promptFinishedTab">
            Prompt (Finished Tab)
          </label>
          <input
            type="text"
            placeholder="Write the first thing that comes to your mind."
            {...register('promptFinishTab')}
            className="w-full rounded p-2 selected:bg-white border-gray-200 border-2 disabled:text-gray-400 disabled:bg-gray-100"
          />
          {formState?.errors.promptFinishTab?.message && (
            <p className="text-red-500 italic text-xs">
              {formState?.errors.promptFinishTab?.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="text-sm text-gray-400" htmlFor="sbtextStarter">
            Subtext - Starter
          </label>
          <input
            type="text"
            placeholder="My hair looks great today!"
            {...register('subtextStarter')}
            className="w-full rounded p-2 selected:bg-white border-gray-200 border-2 disabled:text-gray-400 disabled:bg-gray-100"
          />
          {formState?.errors.subtextStarter?.message && (
            <p className="text-red-500 italic text-xs">
              {formState?.errors.subtextStarter?.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="text-sm text-gray-400" htmlFor="subtextRegular">
            Subtext - Regular
          </label>
          <input
            type="text"
            placeholder="Type or dictate your response here."
            {...register('subtextRegular')}
            className="w-full rounded p-2 selected:bg-white border-gray-200 border-2 disabled:text-gray-400 disabled:bg-gray-100"
          />
          {formState?.errors.subtextRegular?.message && (
            <p className="text-red-500 italic text-xs ">
              {formState?.errors.subtextRegular?.message}
            </p>
          )}
        </div>
      </div>
    </form>
  );
};
