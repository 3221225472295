import { signOut } from 'firebase/auth';
import { firebaseAuth } from 'lib/utils/firebaseConfig';
import React, { useEffect, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { Navigation } from './Navigation';
import { useStore } from 'lib/store';
import { shallow } from 'zustand/shallow';
import classNames from 'classnames';
import { securityService } from 'lib/services/securityServices';
import { showToast } from 'lib/toast';

export const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const { getProfile } = securityService;
  const { userProfile, updateUserProfile } = useStore(
    (state) => ({
      userProfile: state.userProfile,
      updateUserProfile: state.updateUserProfile,
    }),
    shallow,
  );

  const connectProfile = async () => {
    try {
      const profileResponse = await getProfile({});
      updateUserProfile({ ...userProfile, ...profileResponse.data });
    } catch (e) {
      showToast({ type: 'error', title: 'Can`t connect profile' });
    }
  };

  const initials: string =
    userProfile?.userProfile?.firstName && userProfile?.userProfile?.lastName
      ? userProfile?.userProfile?.firstName[0].toUpperCase() +
        userProfile?.userProfile?.lastName[0].toUpperCase()
      : 'RB';
  const displayName =
    userProfile?.userProfile?.firstName && userProfile?.userProfile?.lastName
      ? `${userProfile?.userProfile?.firstName} ${userProfile?.userProfile?.lastName} `
      : userProfile?.email;

  useEffect(() => {
    if (!userProfile?.userProfile) {
      connectProfile();
    }
  }, []);

  return (
    <div className="flex w-full min-h-screen">
      <div id="adminHeader" className="flex flex-col w-full">
        <div className="flex justify-between px-4 py-3 bg-white border-b-[1px] border-gray-100">
          <div className="w-3/5">
            <Navigation />
          </div>
          <div className="flex justify-end items-center">
            <div
              className="flex items-center gap-1"
              onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            >
              <p className="flex w-10 h-10 rounded-3xl bg-blue-50 items-center justify-center">
                <span>{initials}</span>
              </p>
              <HiChevronDown className="w-5 h-5" />
              <div
                id="dropdownMenu"
                className={classNames(
                  'absolute z-10 w-60 bg-white rounded shadow top-16 right-4 p-2',
                  { hidden: !isDropdownVisible },
                )}
              >
                <div className="border-b py-2">
                  <p className="px-2 text-sm overflow-hidden text-ellipsis ">
                    {displayName}
                  </p>
                </div>
                <p
                  className="text-gray-800 text-sm p-2 hover:bg-gray-100 rounded"
                  onClick={() => signOut(firebaseAuth)}
                >
                  Log out
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full h-full bg-white">{children}</div>
      </div>
    </div>
  );
};
