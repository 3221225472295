import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type TextInputProps = {
  control: Control<any, any>;
  name: string;
  label: string;
  inputStyle: string;
  labelStyle: string;
  controlStyle: string;
  errorStyle: string;
  isDisabled?: boolean;
};

export const TextInput: FC<TextInputProps> = ({
  control,
  name,
  inputStyle,
  label,
  labelStyle,
  controlStyle,
  errorStyle,
  isDisabled,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, fieldState }) => (
          <div className={controlStyle}>
            <div style={{ position: 'relative' }}>
              <label htmlFor={name} className={labelStyle}>
                {label}
              </label>
              <input
                type="string"
                value={value}
                onChange={onChange}
                className={`${inputStyle} ${isDisabled ? 'bg-gray-100 text-gray-400' : ''}`}
                disabled={isDisabled}
              />
            </div>
            {fieldState.error && (
              <p className={errorStyle}>{fieldState.error.message}</p>
            )}
          </div>
        )}
      />
    </>
  );
};
