import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type ShowValueFieldProps = {
  value: any;
  label: string;
  labelStyle: string;
  inputStyle: string;
  controlStyle: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
};

export const ReadonlyInput: FC<ShowValueFieldProps> = ({
  value,
  inputStyle,
  label,
  labelStyle,
  controlStyle,
  isDisabled,
}) => {
  return (
    <div>
      <div className={controlStyle}>
        <div style={{ position: 'relative' }}>
          <span className={labelStyle}>{label}</span>
          <input
            type="string"
            value={value}
            className={`cursor-default ${inputStyle} ${isDisabled ? 'bg-gray-100 text-gray-400' : ''}`}
            disabled={isDisabled}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
};
