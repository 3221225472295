import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { pushConfigDraftsService } from 'lib/services/pushConfigDraftsService';
import { showToast } from 'lib/toast';
import { AxiosError } from 'axios';
import { BackendError } from '../types/entities';
import { DAYS_OF_WEEK } from '../enums';

export type PushManagementDraftsSchema = {
  pushNotifications: ReadonlyArray<{
    type: string;
    dayTitle: string;
    dayMessage: string;
    specificDay: boolean;
    deliveryTime: string;
    active: boolean;
    days: ReadonlyArray<{
      active: boolean;
      day: DAYS_OF_WEEK;
      deliveryTime: string;
      id: string;
      specificDay: boolean;
      specificDayTitle: null | string;
      specificDayMessage: null | string;
    }>;
  }>;
};

const { getPushConfigDraftsRequest, patchPushConfigDraftsRequest } =
  pushConfigDraftsService();

export const usePushManagementDraft = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    formState,
    resetField,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm<PushManagementDraftsSchema>({
    defaultValues: {
      pushNotifications: [],
    },
  });

  const { fields } = useFieldArray({
    name: 'pushNotifications',
    control,
  });

  const watchFields = useWatch({
    name: 'pushNotifications',
    control,
  });

  const isNotificationDataValid = (notificationIndex: number): boolean => {
    const notification = watchFields[notificationIndex];

    clearErrors();

    let isValid = true;

    if (notification.dayTitle && notification.dayTitle.trim().length > 0) {
      if (notification.dayTitle.trim().length > 128) {
        setError(`pushNotifications.${notificationIndex}.dayTitle`, {
          message: 'Push title is too long',
        });

        isValid = false;
      }
    } else {
      setError(`pushNotifications.${notificationIndex}.dayTitle`, {
        message: 'Push title is required',
      });

      isValid = false;
    }

    if (notification.dayMessage && notification.dayMessage.trim().length > 0) {
      if (notification.dayMessage.trim().length > 128) {
        setError(`pushNotifications.${notificationIndex}.dayMessage`, {
          message: 'Push message is too long',
        });

        isValid = false;
      }
    } else {
      setError(`pushNotifications.${notificationIndex}.dayMessage`, {
        message: 'Push message is required',
      });

      isValid = false;
    }

    if (notification.specificDay) {
      notification.days.forEach((day, index) => {
        if (day.specificDayTitle && day.specificDayTitle.trim().length > 0) {
          if (day.specificDayTitle.trim().length > 128) {
            setError(
              `pushNotifications.${notificationIndex}.days.${index}.specificDayTitle`,
              {
                message: 'Push title is too long',
              },
            );

            isValid = false;
          }
        } else {
          setError(
            `pushNotifications.${notificationIndex}.days.${index}.specificDayTitle`,
            {
              message: 'Push title is required',
            },
          );

          isValid = false;
        }

        if (
          day.specificDayMessage &&
          day.specificDayMessage.trim().length > 0
        ) {
          if (day.specificDayMessage.trim().length > 128) {
            setError(
              `pushNotifications.${notificationIndex}.days.${index}.specificDayMessage`,
              {
                message: 'Push message is too long',
              },
            );

            isValid = false;
          }
        } else {
          setError(
            `pushNotifications.${notificationIndex}.days.${index}.specificDayMessage`,
            {
              message: 'Push message is required',
            },
          );

          isValid = false;
        }
      });
    } else {
      if (!notification.deliveryTime) {
        setError(`pushNotifications.${notificationIndex}.deliveryTime`, {
          message: 'Push time is required',
        });

        isValid = false;
      }

      if (typeof notification.active !== 'boolean') {
        setError(`pushNotifications.${notificationIndex}.active`, {
          message: 'Active is required',
        });

        isValid = false;
      }
    }

    return isValid;
  };

  const onSaveNotification = async (notificationIndex: number) => {
    if (!isNotificationDataValid(notificationIndex)) {
      return;
    }

    setIsLoading(true);

    const notification = watchFields[notificationIndex];

    const notificationDaysData = notification.days.map((notificationDay) => {
      return new Promise((resolve, reject) => {
        patchPushConfigDraftsRequest(notificationDay.id, {
          specificDay: notification.specificDay,
          specificDayTitle:
            notificationDay.specificDayTitle &&
            notificationDay.specificDayTitle.trim(),
          specificDayMessage:
            notificationDay.specificDayMessage &&
            notificationDay.specificDayMessage.trim(),
          dayTitle: notification.dayTitle && notification.dayTitle.trim(),
          dayMessage: notification.dayMessage && notification.dayMessage.trim(),
          active: notification.specificDay
            ? notificationDay.active
            : notification.active,
          deliveryTime: notification.specificDay
            ? notificationDay.deliveryTime
            : notification.deliveryTime,
        })
          .then(resolve)
          .catch(reject);
      });
    });

    try {
      await Promise.all(notificationDaysData);

      resetField(`pushNotifications`);
      getCurrentNotifications();
    } catch (e) {
      const error = e as AxiosError & { data: BackendError };
      console.error(error);

      if (error.data?.['hydra:description']) {
        showToast({
          type: 'error',
          title: `Error`,
          message: error.data['hydra:description'],
        });
      } else {
        showToast({
          type: 'error',
          title: `Error`,
          message: 'Cannot access cards',
        });
      }

      setIsLoading(false);
    }
  };

  const getCurrentNotifications = async () => {
    const pushConfigResponse = await getPushConfigDraftsRequest();

    const pushConfig = pushConfigResponse.data['hydra:member'].map(
      (pushConfigDay) => ({
        active: pushConfigDay.active,
        dayTitle: pushConfigDay.dayTitle,
        dayMessage: pushConfigDay.dayMessage,
        deliveryTime: pushConfigDay.deliveryTime,
        specificDay: pushConfigDay.specificDay,
        type: pushConfigDay.type,
        days: pushConfigDay.days.map((day) => ({
          ...day,
          specificDayTitle: day.specificDayTitle || '',
          specificDayMessage: day.specificDayMessage || '',
        })),
      }),
    );

    setValue('pushNotifications', pushConfig);

    setIsLoading(false);
  };

  useEffect(() => {
    getCurrentNotifications();
  }, []);

  return {
    onSaveNotification,
    control,
    handleSubmit,
    formState,
    fields,
    watchFields,
    isLoading,
  };
};

export default usePushManagementDraft;
