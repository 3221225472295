import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { UseFormSetError, useForm } from 'react-hook-form';
import { HiArrowLeft, HiOutlineTrash } from 'react-icons/hi';
import { UserInfoTab } from '../modalTabsTemplates/UserInfoTab';
import { UserProfileShape } from '../../../lib/types/store';
import { UserCardsTab } from '../modalTabsTemplates/UserCardsTab';
import { userService } from 'lib/services/userServices';
import { showToast } from 'lib/toast';
import { cardPlaysService } from 'lib/services/userCardPlaysService';
import { formatDate } from 'lib/utils/formatDate';
import { USER_ROLES } from 'lib/enums';

export type UserInfoModalProps = {
  readonly userId: string;
  readonly title?: string;
  readonly closeModal: () => void;
  readonly onDelete: (id: string) => void;
  readonly onUpdate: (
    user: UserProfileShape,
    setError: UseFormSetError<UserProfileShape>,
  ) => void;
  readonly onTestStatusUpdate: () => void;
};

const userInfoTabs: { [x: string]: string } = {
  INFO: 'Info',
  CARDS: 'Cards',
};

const { getCardPlays } = cardPlaysService();
const { getUser, switchPreviewRole } = userService();

export const UserInfoModal: React.FC<UserInfoModalProps> = ({
  userId,
  closeModal,
  onUpdate,
  onDelete,
  onTestStatusUpdate,
}) => {
  const [currentTab, setCurrentTab] = useState(userInfoTabs.INFO);
  const [cardPlaysCount, setCardPlaysCount] = useState<number>(0);
  const [isTester, setIsTester] = useState(false);
  const [user, setUser] = useState<UserProfileShape>({
    id: userId,
    '@id': userId,
    phone: '(402) 374-1462',
    email: 'example@email.com',
    userProfile: {
      countryCode: 'USA',
      stateCode: 'Enfield',
      city: 'North Carolina',
      timezone: 'America/New_York',
      birthdate: '',
      tabsPerDay: '1/1',
      subscriptionType: '',
      revenueCatLink: '',
    },
    provider: 'Email',
  });
  const getFullUserInfo = async () => {
    try {
      getCardPlayStat();
      const userInfoResponse = await getUser({ firebaseId: userId });
      const userInfoData = userInfoResponse.data;
      const {
        email,
        phone,
        roles,
        provider,
        isTester,
        userProfile,
        createdAt,
      } = userInfoData;

      setUser({
        ...user,
        email,
        createdAt,
        userProfile,
        phone,
        provider:
          provider === 'password' ? 'Email' : provider.replace('.com', ''),
        roles,
        name:
          userInfoData.userProfile.firstName +
          ' ' +
          userInfoData.userProfile.lastName,
      });
      setIsTester(isTester);
      setValue('email', email);
      setValue('phone', phone ? phone : '');
      setValue('userProfile.city', userProfile.city ? userProfile.city : '');
      setValue(
        'userProfile.countryCode',
        userProfile.countryCode ? userProfile.countryCode : '',
      );
      setValue(
        'userProfile.stateCode',
        userProfile.stateCode ? userProfile.stateCode : '',
      );
      setValue(
        'userProfile.timezone',
        userProfile.timezone ? userProfile.timezone : '',
      );
      setValue(
        'userProfile.birthdate',
        userProfile.birthdate ? userProfile.birthdate : '',
      );
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot access user profile',
      });
    }
  };

  const { register, setValue, formState, handleSubmit, watch, setError } =
    useForm<UserProfileShape>({
      defaultValues: {
        ...user,
      },
      mode: 'onChange',
    });

  const onSubmit = (updatedUser: UserProfileShape) => {
    onUpdate(updatedUser, setError);
  };
  const getCardPlayStat = async () => {
    const cardPlaysResponse = await getCardPlays({
      params: {
        page: 1,
        itemsPerPage: 1,
        'cardPlaylist.user': userId,
      },
    });
    const cardPlaysTotal = cardPlaysResponse.data['hydra:totalItems'];
    setCardPlaysCount(cardPlaysTotal);
  };

  const updateTestRole = async () => {
    try {
      await switchPreviewRole({
        firebaseId: userId,
      });
      showToast({ type: 'success', title: 'Tester role updated' });
      setIsTester(!isTester);
      onTestStatusUpdate();
    } catch (e) {
      showToast({ type: 'error', title: 'Tester role update failed' });
    }
  };

  useEffect(() => {
    getFullUserInfo();
  }, []);

  return (
    <div className="flex flex-col h-full flex-1">
      <div className="flex justify-between items-start px-5 rounded-t relative">
        <h3 className="mt-16 mb-6 max-h-[60px] overflow-y-hidden text-xl font-medium text-gray-900">
          {user?.name}
        </h3>
        <button
          type="button"
          className="absolute left-3 top-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={() => closeModal()}
        >
          <HiArrowLeft className="w-5 h-5 " />
        </button>
        {user?.roles && user?.roles.includes(USER_ROLES.ADMIN) ? null : (
          <div className="text-sm text-gray-800 flex absolute top-5 right-3">
            <div className="flex items-center rounded hover:bg-gray-100 p-2">
              <HiOutlineTrash
                className="w-5 h-5"
                onClick={() => onDelete(user.id)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col px-5">
        <div className="flex text-sm w-full mb-3 items-center">
          <p className="text-gray-500 w-36 text-md">ID</p>
          <p className="text-gray-800 font-medium py-1 px-2">{user?.id}</p>
        </div>
        {user?.userProfile?.subscriptionType && (
          <div className="flex text-sm w-full mb-3 items-center">
            <p className="text-gray-500 w-36 text-md">Subscription Type</p>
            <p className="text-gray-800 font-medium py-1 px-2">
              {user?.userProfile?.subscriptionType}
            </p>
          </div>
        )}
        {user?.userProfile?.subscriptionStatus && (
          <div className="flex text-sm w-full mb-3 items-center">
            <p className="text-gray-500 w-36 text-md">Subscription Status</p>
            <p className="text-gray-800 font-medium py-1 px-2 capitalize">
              {user?.userProfile?.subscriptionStatus}
            </p>
          </div>
        )}

        {user?.provider && (
          <div className="flex text-sm w-full mb-3 items-center">
            <p className="text-gray-500 w-36 text-md">Sign-up Origin</p>
            <p className="text-gray-800 font-medium py-1 px-2 capitalize">
              {user?.provider || 'Email'}
            </p>
          </div>
        )}
        <div className="flex text-sm w-full mb-3 items-center">
          <p className="text-gray-500 w-36 text-md">Time zone</p>
          <p className="text-gray-900 font-medium py-1 px-2">
            {user?.userProfile?.timezone || '-'}
          </p>
        </div>
        <div className="flex text-sm w-full mb-3 items-center">
          <p className="text-gray-500 w-36 text-md">Customer Since</p>
          <p className="text-gray-900 font-medium py-1 px-2">
            {user?.createdAt ? formatDate(new Date(user?.createdAt)) : '-'}
          </p>
        </div>
        <div className="flex text-sm w-full mb-3 items-center">
          <p className="text-gray-500 w-36 text-md">Tabs per Day</p>
          <p className="text-gray-800 font-medium py-1 px-2 flex items-center">
            {user.userProfile?.tabsPerDay}
            <p className="ml-2 text-gray-500 text-xs">
              (tabs played / days since signed up)
            </p>
          </p>
        </div>
        <div className="h-full flex-1">
          {user.userProfile?.revenueCatLink && (
            <div className="bg-white h-16 flex items-center">
              <a
                href={user.userProfile?.revenueCatLink}
                className="bg-blue-500 text-white p-2 rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check payment history
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 px-1.5">
        <ul className="flex flex-wrap -mb-p text-sm ml-2">
          {Object.keys(userInfoTabs).map((tab) => (
            <li key={tab}>
              <button
                onClick={() => {
                  setCurrentTab(userInfoTabs[tab]);
                }}
                className={classNames(
                  'inline-block mx-1.5 mt-2 pb-2 rounded-t-lg border-b hover:text-gray-600 hover:border-gray-300',
                  {
                    'text-gray-700 border-black':
                      currentTab === userInfoTabs[tab],
                    'border-transparent': currentTab !== userInfoTabs[tab],
                  },
                )}
              >
                <p className="flex">
                  {userInfoTabs[tab]}
                  {tab === 'CARDS' && !!cardPlaysCount && (
                    <p className="ml-1 text-black bg-gray-200 px-1 flex justify-center items-center text-xs rounded">
                      {cardPlaysCount}
                    </p>
                  )}
                </p>
              </button>
            </li>
          ))}
        </ul>
      </div>
      {currentTab === userInfoTabs.INFO && (
        <UserInfoTab
          watch={watch}
          register={register}
          formState={formState}
          isTester={isTester}
          updateTestRole={updateTestRole}
          onUpdateDate={(value) =>
            setValue('userProfile.birthdate', value, { shouldDirty: true })
          }
        />
      )}
      {currentTab === userInfoTabs.CARDS && (
        <UserCardsTab selectedUserId={userId} />
      )}
      {formState?.isDirty && (
        <div className="bg-white border-t h-16 flex justify-end items-center px-4">
          <button
            type="button"
            className="bg-blue-500 text-white p-2 rounded"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};
