import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';

type CustomSelectProps = {
  options: { label: string; value: string | boolean }[];
  control?: Control<any>;
  name: string;
  title: string;
  titleStyle?: string;
  controlStyle?: string;
  error?: string;
  onCustomChange?: (value: string) => void;
  value?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
};

export const CustomSelect: FC<CustomSelectProps> = ({
  options,
  control,
  name,
  title,
  titleStyle,
  controlStyle,
  value,
  error,
  isSearchable = false,
  onCustomChange,
  isDisabled,
}) => {
  return control ? (
    <div className={`w-full relative ${controlStyle}`}>
      <label
        className={`text-sm text-gray-400 ${titleStyle}`}
        htmlFor="responseType"
      >
        {title}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <Select
              {...field}
              isDisabled={isDisabled}
              options={options}
              isSearchable={isSearchable}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: '2px 3px 2px 3px',
                  borderWidth: '2px',
                  borderColor: 'rgb(229 231 235)',
                }),
              }}
              value={options.find((c) => {
                if (typeof c.value === 'string') {
                  return c.value === String(field.value);
                }
                return c.value === field.value;
              })}
              onChange={(selectedOption) => {
                field.onChange(selectedOption?.value);
              }}
            />
            {fieldState.error && (
              <p className="text-red-500 italic text-xs">
                {fieldState.error.message}
              </p>
            )}
          </>
        )}
      />
      {error && <p className="text-red-500 italic text-xs">{error}</p>}
    </div>
  ) : (
    <div className="w-full relative">
      <Select
        menuPlacement="auto"
        options={options}
        isSearchable={isSearchable}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: '4px',
          }),
        }}
        value={options.find((c) => {
          return c.value === value;
        })}
        onChange={(selectedOption) => {
          if (!selectedOption) return;
          if (typeof selectedOption?.value === 'string')
            onCustomChange && onCustomChange(selectedOption.value);
        }}
      />
    </div>
  );
};
