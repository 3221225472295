import React, { memo } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { columnList } from './Data';
import { CardsTableRow } from '../../../lib/types/tables';
import { useStore } from 'lib/store';
import { shallow } from 'zustand/shallow';
import { TableSorting } from 'lib/types/entities';
import classNames from 'classnames';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';
import { formatColumnName } from './helpers';

export type CardsTableProps = {
  onItemClick: (value: string) => void;
  onSortClick: (value: TableSorting) => void;
  currentSorting: TableSorting;
  isDraft?: boolean;
};

export const CardsTable: React.FC<CardsTableProps> = memo(
  ({ onItemClick, onSortClick, currentSorting, isDraft }) => {
    const { cardsTableData } = useStore(
      (state) => ({
        cardsTableData: state.cardsTableData,
      }),
      shallow,
    );

    const data = React.useMemo<CardsTableRow[]>(
      () =>
        cardsTableData.map(
          ({
            id,
            type,
            limit,
            title,
            advanced,
            mediaType,
            responseType,
            active,
            frequency,
            similiatityGroup,
            sequenceNumber,
          }) => {
            return {
              id: id || '-',
              type: type || '-',
              title: title || '-',
              active: active || false,
              limit: limit || '-',
              advanced: advanced || false,
              frequency: frequency || 0,
              similiatityGroup: similiatityGroup || 0,
              sequenceNumber: sequenceNumber || '-',
              mediaType: mediaType || '-',
              responseType: responseType || '-',
            };
          },
        ),
      [cardsTableData],
    );
    const columns = React.useMemo<Column<CardsTableRow>[]>(
      () => columnList,
      [],
    );
    const initialState = { hiddenColumns: ['id'] };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data, initialState });

    const onColumnClick = (column: string) => {
      const actualColumnName = isDraft ? formatColumnName(column) : column;

      if (actualColumnName === 'limit') {
        if (currentSorting?.responseLimitNum === 'desc') {
          onSortClick({
            responseLimitNum: 'asc',
          });
          return;
        } else {
          onSortClick({
            responseLimitNum: 'desc',
          });
          return;
        }
      }

      if (currentSorting[actualColumnName] === 'desc') {
        onSortClick({ [actualColumnName]: 'asc' });
      } else {
        onSortClick({
          [actualColumnName]: 'desc',
        });
      }
    };

    return (
      <table
        {...getTableProps()}
        className="w-full text-xs border border-gray-200"
      >
        <thead className="h-11 border-b border-gray-200 px-4">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-gray-400 font-medium"
            >
              {headerGroup.headers.map((column) => {
                const actualColumnName = isDraft
                  ? formatColumnName(column.id)
                  : column.id;
                return (
                  <th {...column.getHeaderProps()} className="h-10 px-2">
                    <div
                      className={classNames(
                        'flex justify-start items-center cursor-pointer',
                        {
                          'text-blue-500':
                            column.id === 'limit'
                              ? currentSorting.responseLimitNum
                              : currentSorting?.[actualColumnName],
                        },
                      )}
                      onClick={() => {
                        onColumnClick(column.id);
                      }}
                    >
                      {column.render('Header')}
                      <p className="mx-1 h-4 relative">
                        <GoTriangleUp
                          className={classNames('w-2 h-2 top-[1px] absolute', {
                            hidden:
                              column.id === 'limit'
                                ? currentSorting.responseLimitNum
                                : currentSorting?.[actualColumnName],
                          })}
                        />
                        <GoTriangleDown
                          className={classNames(
                            'w-2 h-2 bottom-[1px] absolute',
                            {
                              hidden:
                                column.id === 'limit'
                                  ? currentSorting.responseLimitNum
                                  : currentSorting?.[actualColumnName],
                            },
                          )}
                        />
                        <HiArrowDown
                          className={classNames(
                            'w-4 h-4 bottom-[1px] absolute',
                            {
                              hidden:
                                column.id === 'limit'
                                  ? currentSorting.responseLimitNum !== 'desc'
                                  : currentSorting?.[actualColumnName] !==
                                    'desc',
                            },
                          )}
                        />
                        <HiArrowUp
                          className={classNames(
                            'w-4 h-4 bottom-[1px] absolute',
                            {
                              hidden:
                                column.id === 'limit'
                                  ? currentSorting.responseLimitNum !== 'asc'
                                  : currentSorting?.[actualColumnName] !==
                                    'asc',
                            },
                          )}
                        />
                      </p>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={(event) => {
                  const target = event.target as Element;
                  if (
                    target.tagName !== 'INPUT' &&
                    target.tagName !== 'BUTTON'
                  ) {
                    onItemClick(row.values.id);
                  }
                }}
                className="h-10 text-left border-b hover:bg-blue-100"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="px-4 mr-auto">
                      {cell.column.id === 'type' ? (
                        <p className="capitalize">{cell.render('Cell')}</p>
                      ) : cell.column.id === 'active' ? (
                        <p>{cell.value ? 'Active' : 'Inactive'}</p>
                      ) : cell.column.id === 'advanced' ? (
                        <div className="flex space-x-1">
                          {cell.value ? (
                            <>
                              <p className="w-6">Yes</p>
                              <p>☑️</p>
                            </>
                          ) : (
                            <>
                              <p className="w-6">No</p>
                              <p>⬜</p>
                            </>
                          )}
                        </div>
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);
