import { UserCardPlaysTable } from 'components/tables/UserCardPlaysTable';
import { useEffect, useState } from 'react';
import {
  CardPlayResponse,
  cardPlaysService,
} from 'lib/services/userCardPlaysService';
import { PaginationFooter } from 'components/common/PaginationFooter';
import { showToast } from 'lib/toast';
import { UserCardsTableRow } from 'lib/types/tables';
import { formatDateWithTime } from 'lib/utils/formatDate';
import { TableSorting } from 'lib/types/entities';

const { getCardPlays } = cardPlaysService();

export const UserCardsTab: React.FC<{ selectedUserId: string }> = ({
  selectedUserId,
}) => {
  const [currentSortingParams, setCurrentSortingParams] =
    useState<TableSorting>({
      'card.sequenceNumber': 'desc',
    });
  const [cardsTableData, setCardsTableData] = useState<UserCardsTableRow[]>([]);
  const [currentUserPlaysParams, setCurrentUserPlaysParams] = useState({
    page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  const connectCardPlays = async (newPage?: number) => {
    try {
      const cardPlaysResponse = await getCardPlays({
        params: {
          page: newPage || currentUserPlaysParams.page,
          itemsPerPage: currentUserPlaysParams.itemsPerPage,
          'cardPlaylist.user': selectedUserId,
          order: currentSortingParams,
        },
      });
      const data = cardPlaysResponse.data['hydra:member'].map(
        ({ card, status, updatedAt }: CardPlayResponse) => {
          return {
            id: card['@id'],
            sequenceNumber: card.sequenceNumber,
            title: card.title || '-',
            status,
            updatedAt: updatedAt ? formatDateWithTime(updatedAt) : '-',
          };
        },
      );

      setCurrentUserPlaysParams({
        ...currentUserPlaysParams,
        page: newPage || currentUserPlaysParams.page,
        totalItems: cardPlaysResponse.data['hydra:totalItems'],
      });
      setCardsTableData(data);
    } catch (error) {
      showToast({
        type: 'error',
        title: `Error`,
        message: 'Cannot access user card plays',
      });
    }
  };
  const onSortClick = (sorting: TableSorting) => {
    setCurrentSortingParams(sorting);
  };

  useEffect(() => {
    connectCardPlays();
  }, [currentSortingParams]);

  return (
    <div className="flex w-full flex-col flex-1 px-5 pt-8 overflow-auto">
      <div className="h-full flex flex-col justify-between">
        {!!cardsTableData.length ? (
          <UserCardPlaysTable
            onItemClick={() => null}
            onSortClick={onSortClick}
            cardsTableData={cardsTableData}
          />
        ) : (
          <p className="text-sm p-2">No cards found...</p>
        )}
        <PaginationFooter
          itemsPerPage={currentUserPlaysParams.itemsPerPage}
          currentPage={currentUserPlaysParams.page}
          totalItems={currentUserPlaysParams.totalItems}
          onPageClick={(newPage) => connectCardPlays(newPage)}
        />
      </div>
    </div>
  );
};
