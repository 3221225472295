import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { AiOutlineFileUnknown } from 'react-icons/ai';

type MediaFilesFiltersPopUpProps = {
  isVisible: boolean;
  onClose: () => void;
  onChangeFilters: (filters: { type: string }) => void;
};

const TYPE_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Audio', value: 'audio' },
  { label: 'Video', value: 'video' },
  { label: 'Image', value: 'image' },
];

export const MediaFilesFiltersPopUp: React.FC<MediaFilesFiltersPopUpProps> = ({
  isVisible,
  onClose,
  onChangeFilters,
}) => {
  const handleResetFilters = () => {
    onChangeFilters({ type: '' });
    onClose();
  };

  return (
    <div
      id="dropdown"
      className={classNames(
        'absolute z-10 w-60 bg-white rounded shadow top-14 -left-24 p-4',
        { hidden: !isVisible },
      )}
    >
      <p className="text-black font-medium pb-4">All Filters</p>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs pb-4">
        <div className="flex items-center">
          <AiOutlineFileUnknown className="w-5 h-5 text-black" />
          <p className="text-black font-bold px-2">File Type</p>
        </div>

        <select
          name="type"
          id="type"
          className="w-20 bg-white mr-1"
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            onChangeFilters({ type: event.target.value });
            onClose();
          }}
        >
          {TYPE_OPTIONS.map((origin) => (
            <option key={origin.label} value={origin.value}>
              {origin.label}
            </option>
          ))}
        </select>
      </div>
      <button
        type="button"
        className="w-full text-gray-500 border border-gray-200 flex text-center justify-center rounded py-1 text-sm"
        onClick={handleResetFilters}
      >
        Default settings
      </button>
    </div>
  );
};
