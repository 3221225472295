import classNames from 'classnames';
import { ChangeEvent, useState } from 'react';
import {
  HiOutlineDocumentText,
  HiOutlinePuzzle,
  HiOutlineRefresh,
  HiOutlineReply,
  HiOutlineSparkles,
  HiOutlineStatusOnline,
} from 'react-icons/hi';
import { TabFilters } from 'lib/types/entities';
import {
  ACTIVE_OPTIONS,
  ADVANCED_OPTIONS,
  DEFAULT_FILTERS,
  FREQUENCY_OPTIONS,
  RESPONSE_OPTIONS,
  TYPE_OPTIONS,
} from './data';

type TabFiltersPopUpProps = {
  isVisible: boolean;
  onClose: () => void;
  onChangeFilters: (filters: TabFilters) => void;
};

export const TabFiltersPopUp: React.FC<TabFiltersPopUpProps> = ({
  isVisible,
  onClose,
  onChangeFilters,
}) => {
  const [filters, setFilters] = useState<TabFilters>(DEFAULT_FILTERS);

  const handleResetFilters = () => {
    onChangeFilters(DEFAULT_FILTERS);
    setFilters(DEFAULT_FILTERS);
    onClose();
  };
  const handleApplyFilters = () => {
    onChangeFilters(filters);
    onClose();
  };

  return (
    <div
      id="dropdown"
      className={classNames(
        'absolute z-10 w-80 bg-white rounded shadow top-14 right-24 p-4',
        { hidden: !isVisible },
      )}
    >
      <p className="text-black font-medium pb-4">All Filters</p>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs pb-4">
        <div className="flex items-center">
          <HiOutlineSparkles className="w-5 h-5 text-black" />
          <p className="text-black font-bold px-1 text-sm">Card Type</p>
        </div>
        <select
          name="type"
          value={filters.type}
          id="origin"
          className={classNames('bg-white mr-1 cursor-pointer', {
            'w-10': !filters.type,
          })}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setFilters({ ...filters, type: event.target.value })
          }
        >
          {TYPE_OPTIONS.map((origin) => (
            <option key={origin.label} value={origin.value}>
              {origin.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs mb-4">
        <div className="flex items-center">
          <HiOutlineDocumentText className="w-4 h-4 text-black" />
          <p className="text-black font-bold px-2 text-sm">Title</p>
        </div>
        <input
          placeholder="Enter tab title"
          value={filters.title}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFilters({ ...filters, title: event.target.value })
          }
          type="text"
          className="border rounded p-2"
        />
      </div>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs pb-4">
        <div className="flex items-center">
          <HiOutlineReply className="w-5 h-5 text-black" />
          <p className="text-black font-bold px-1 text-sm">Response Type</p>
        </div>
        <select
          name="responseType"
          value={filters.responseType}
          id="origin"
          className={classNames('bg-white mr-1 cursor-pointer', {
            'w-10': !filters.responseType,
          })}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setFilters({ ...filters, responseType: event.target.value })
          }
        >
          {RESPONSE_OPTIONS.map((origin) => (
            <option key={origin.label} value={origin.value}>
              {origin.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs mb-4">
        <div className="flex items-center">
          <HiOutlineStatusOnline className="w-4 h-4 text-black" />
          <p className="text-black font-bold px-2 text-sm">Card Status</p>
        </div>
        <select
          name="active"
          value={JSON.stringify(filters.active)}
          className={classNames('bg-white mr-1 cursor-pointer', {
            'w-10': !filters.active,
          })}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setFilters({ ...filters, active: JSON.parse(event.target.value) })
          }
        >
          {ACTIVE_OPTIONS.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs mb-4">
        <div className="flex items-center">
          <HiOutlinePuzzle className="w-4 h-4 text-black" />
          <p className="text-black font-bold px-2 text-sm">Advanced</p>
        </div>
        <select
          name="advanced"
          value={JSON.stringify(filters.advanced)}
          className={classNames('bg-white mr-1 cursor-pointer', {
            'w-10': !filters.advanced,
          })}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setFilters({ ...filters, advanced: JSON.parse(event.target.value) })
          }
        >
          {ADVANCED_OPTIONS.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs pb-4">
        <div className="flex items-center">
          <HiOutlineRefresh className="w-5 h-5 text-black" />
          <p className="text-black font-bold px-1 text-sm">Frequency</p>
        </div>
        <select
          name="frequency"
          value={filters.frequency}
          id="origin"
          className={classNames('bg-white mr-1 cursor-pointer', {
            'w-10': !filters.frequency,
          })}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setFilters({ ...filters, frequency: event.target.value })
          }
        >
          {FREQUENCY_OPTIONS.map((origin) => (
            <option key={origin.label} value={origin.value}>
              {origin.label}
            </option>
          ))}
        </select>
      </div>
      <button
        type="button"
        className="w-full text-gray-500 border border-gray-200 flex text-center justify-center rounded py-1 text-sm"
        onClick={handleResetFilters}
      >
        Default settings
      </button>
      <button
        type="button"
        className="w-full bg-blue-500 text-white border border-gray-200 flex text-center justify-center rounded py-1 my-2 text-sm"
        onClick={handleApplyFilters}
      >
        Apply
      </button>
    </div>
  );
};
