import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';

type LoginFields = {
  email: string;
  password: string;
  rememberMe: boolean;
};

// TODO: add form validation like Yup

type LoginFormProps = {
  recaptchaContainer: JSX.Element;
  onSubmitForm: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmitForm,
  recaptchaContainer,
}) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const { register, handleSubmit } = useForm<LoginFields>();
  const onSubmit: SubmitHandler<LoginFields> = (data) => {
    const { email, password } = data;
    onSubmitForm({ email, password });
  };

  return (
    <section className="shadow-md">
      <div className="px-12 py-10">
        <p className="text-gray-800 text-xl font-medium pb-8">Log In</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col relative mb-4">
            <label htmlFor="email" className="text-gray-400 pb-2 text-sm">
              Email
            </label>
            <input
              type="text"
              className="h-10 px-2 py-2 border-gray-200 border-solid border-2 rounded"
              {...register('email')}
              id="email"
            />
          </div>
          <div className="flex flex-col relative mb-4">
            <label htmlFor="password" className="text-gray-400 pb-2 text-sm">
              Password
            </label>
            <div className="mb-6 relative">
              <input
                type={isPasswordShown ? 'text' : 'password'}
                className="w-full px-2 py-2 h-10 pr-12 border-gray-200 border-solid border-2 rounded "
                id="password"
                {...register('password')}
              />
              {isPasswordShown ? (
                <HiOutlineEyeOff
                  className="absolute right-4 top-2.5 w-5 h-5"
                  onClick={() => setIsPasswordShown(false)}
                />
              ) : (
                <HiOutlineEye
                  className="absolute right-4 top-2.5 w-5 h-5"
                  onClick={() => setIsPasswordShown(true)}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center">
            {recaptchaContainer}
            <button className="bg-blue-500 py-2 text-white rounded mb-4">
              Continue
            </button>
            <NavLink
              to="/forgotPassword"
              className="underline text-blue-500 text-center"
            >
              Forgot Password?
            </NavLink>
          </div>
        </form>
      </div>
    </section>
  );
};
