import React from 'react';
import { UserProfileShape } from 'lib/types/store';

export type UserTableProps = {
  onItemClick: (value: string) => void;
  searchResultData: UserProfileShape[];
  searchBy: string;
  searchValue: string;
};

export const SearchUserResults: React.FC<UserTableProps> = ({
  onItemClick,
  searchResultData,
  searchBy,
  searchValue,
}) => {
  if (searchBy && searchValue && !searchResultData.length) {
    return <p className="py-3 px-6">No users found...</p>;
  }
  const highlightSearchValue = (text: string) => {
    const index = text.toLowerCase().indexOf(searchValue.toLowerCase());
    if (index !== -1) {
      return (
        <>
          {text.slice(0, index)}
          <span className="font-bold">
            {text.slice(index, index + searchValue.length)}
          </span>
          {text.slice(index + searchValue.length)}
        </>
      );
    }
    return text;
  };
  return searchResultData.length ? (
    <ul className="w-full max-h-60 overflow-y-auto">
      {searchResultData.map((user) => {
        return (
          <li
            key={user.id}
            className="bg-white w-full text-black py-3 px-6 hover:bg-gray-50 rounded-lg"
            onClick={() => onItemClick(user?.['@id'].replace('/users/', ''))}
          >
            {searchBy === 'userProfile.firstName' ? (
              <>
                <span className="text-black">
                  {highlightSearchValue(
                    user?.userProfile?.firstName +
                      ' ' +
                      user.userProfile?.lastName,
                  )}
                </span>
              </>
            ) : (
              highlightSearchValue(
                `${user[searchBy as keyof UserProfileShape]}`,
              )
            )}
          </li>
        );
      })}
    </ul>
  ) : null;
};
