import {
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useStore } from 'lib/store';
import { showToast } from 'lib/toast';
import { SignInSteps } from 'lib/types/store';
import { firebaseAuth } from 'lib/utils/firebaseConfig';
import { recaptchaVerifierInit } from 'lib/utils/recaptchaVerifierInit';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { ModalLayout, ModalMode } from './../../layouts/ModalLayout';
import { LoginForm } from './LoginForm';
import { OTPFields, OTPForm } from './OTPForm';
import { securityService } from 'lib/services/securityServices';
import { FirebaseError } from 'firebase/app';
import { UseFormSetError } from 'react-hook-form';
import { AxiosResponse } from 'axios';

export const LoginPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { getProfile } = securityService;
  const { currentSignInStep, updateUserProfile, updateCurrentSignInStep } =
    useStore(
      (state) => ({
        updateCurrentSignInStep: state.updateCurrentSignInStep,
        currentSignInStep: state.currentSignInStep,
        updateUserProfile: state.updateUserProfile,
        cleanUserProfile: state.cleanUserProfile,
      }),
      shallow,
    );

  const onSubmitLoginForm = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const getUserProfile = await getProfile({});
        if (!user.emailVerified) {
          // sendEmailVerification(user);
          // showToast({
          //   type: 'info',
          //   title: 'Email verification',
          //   message: 'Verification link was sent to your email',
          // });
        }
        updateUserProfile({ ...user, ...getUserProfile.data });
        showToast({ type: 'success', title: 'Login', message: 'Welcome' });
        navigate('/users');
        updateCurrentSignInStep(SignInSteps.EMAIL);
      })
      .catch((error) => {
        const errorCode = error?.code;

        if (errorCode === 'auth/multi-factor-auth-required') {
          recaptchaVerifierInit();
          window.recaptchaVerifier.render();
          window.firebaseError = error;
          window.onRecaptchaSuccess = async () => {
            const resolver = getMultiFactorResolver(firebaseAuth, error);
            const recaptcha = window.recaptchaVerifier;
            const phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            };
            const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth);

            window.verificationId = await phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              recaptcha,
            );
          };
        }
        if (
          error?.code === 'auth/too-many-attempts' ||
          error?.code === 'auth/too-many-requests'
        ) {
          showToast({
            type: 'error',
            title: 'Too many attempts',
            message: 'Please try again later.',
          });
        }
        if (
          error?.code === 'auth/user-not-found' ||
          error?.code === 'auth/wrong-password'
        ) {
          showToast({
            type: 'error',
            title: 'Incorrect email or password',
            message: 'Check your data and try again.',
          });
        }
        updateCurrentSignInStep(SignInSteps.EMAIL);
      });
  };

  const onSubmitOTPForm = async (
    code: string,
    setError: UseFormSetError<OTPFields>,
  ) => {
    try {
      const verificationId = window.verificationId;
      const firebaseError = window.firebaseError;
      const resolver = getMultiFactorResolver(firebaseAuth, firebaseError);
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const resolvedSignIn = await resolver.resolveSignIn(multiFactorAssertion);
      const getUserProfile = await getProfile({});
      updateUserProfile({ ...resolvedSignIn.user, ...getUserProfile.data });

      showToast({
        type: 'success',
        title: 'Login',
        message: 'Welcome',
      });
      navigate('/users');
      updateCurrentSignInStep(SignInSteps.EMAIL);
    } catch (error: unknown | FirebaseError) {
      // TODO: handle wrong code error
      const firebaseError = error as AxiosResponse & FirebaseError;
      if (firebaseError?.code === 'auth/invalid-verification-code') {
        setError('code', { message: 'Invalid code' });
        showToast({
          type: 'error',
          title: 'Error',
          message: 'Invalid code',
        });
      } else {
        showToast({
          type: 'error',
          title: 'Error',
          message: 'Something went wrong',
        });
      }
    }
  };

  return (
    <ModalLayout mode={ModalMode.center}>
      {currentSignInStep === SignInSteps.EMAIL && (
        <LoginForm
          onSubmitForm={onSubmitLoginForm}
          recaptchaContainer={
            <div id="recaptcha" className="py-4 w-full flex justify-center" />
          }
        />
      )}
      {currentSignInStep === SignInSteps.OTP && (
        <OTPForm onSubmitForm={onSubmitOTPForm} />
      )}
    </ModalLayout>
  );
};
