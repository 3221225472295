import { UserProfileShape } from 'lib/types/store';
import api, { PagingParams } from './api';
import { TableSorting, TesterFilterOption } from 'lib/types/entities';

type GetUsersRequestParams = {
  params: PagingParams & {
    email?: string;
    phone?: string;
    'userProfile.firstName'?: string;
    'userProfile.lastName'?: string;
    firebaseAuthProvider?: string;
    createdAt?: {
      before?: string;
      after?: string;
      strictly_before?: string;
      strictly_after?: string;
    };
    by_roles?: TesterFilterOption;
    order?: TableSorting;
  };
};
type RequestWithId = { firebaseId: string };
type GetUserResponse = {
  'hydra:member': UserProfileShape[];
  'hydra:totalItems': number;
};

export const userService = () => {
  const getUsers = ({
    params = { page: 1, itemsPerPage: 30 },
  }: GetUsersRequestParams) => {
    return api({}).get<GetUserResponse>('/users', {
      params,
    });
  };
  const postUser = ({ data }: { data: GetUsersRequestParams }) => {
    return api({}).post('/users', data);
  };
  const getUser = ({ firebaseId }: RequestWithId) => {
    return api({}).get(`/users/${firebaseId}`);
  };
  const patchUser = ({
    firebaseId,
    data,
  }: RequestWithId & { data: UserProfileShape }) => {
    return api({}).patch(`/users/${firebaseId}`, data, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    });
  };
  const deleteUser = ({ firebaseId }: RequestWithId) => {
    return api({}).delete(`/users/${firebaseId}`);
  };
  const switchPreviewRole = ({ firebaseId }: RequestWithId) => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/users/${firebaseId}/switch_preview_role`, {});
  };

  const getUsersExportExcel = ({ params }: GetUsersRequestParams) => {
    return api({ headers: { 'Content-Type': 'application/ld+json' } }).get(
      '/users/export/excel',
      {
        params,
        responseType: 'blob',
      },
    );
  };

  return {
    switchPreviewRole,
    deleteUser,
    getUsersExportExcel,
    patchUser,
    getUser,
    postUser,
    getUsers,
  };
};
