import { TesterFilterOption } from 'lib/types/entities';

type SelectOptionsArray = ReadonlyArray<{
  value: string;
  label: string;
}>;

export const TYPE_OPTIONS: SelectOptionsArray = [
  { label: 'All', value: '' },
  { label: 'Mindfulness', value: 'mindfulness' },
  { label: 'Affirmation', value: 'affirmation' },
  { label: 'Gratitude', value: 'gratitude' },
  { label: 'Intention', value: 'intention' },
  { label: 'Kudos', value: 'kudos' },
];
export const RESPONSE_OPTIONS: SelectOptionsArray = [
  { label: 'All', value: '' },
  { label: 'Photo', value: 'photo' },
  { label: 'Text', value: 'text' },
];

export const ACTIVE_OPTIONS: SelectOptionsArray = [
  { label: 'All', value: 'null' },
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
];

export const TESTER_ROLE_OPTIONS: SelectOptionsArray = [
  { label: 'All', value: '' },
  { label: 'Active', value: TesterFilterOption.TESTER },
  { label: 'Inactive', value: TesterFilterOption.NO_TESTER },
];

export const ADVANCED_OPTIONS: SelectOptionsArray = [
  { label: 'All', value: 'null' },
  { label: 'Advanced', value: 'true' },
  { label: 'Not Advanced', value: 'false' },
];
const FREQUENCY_VALUES = Array(120)
  .fill(0)
  .map((_, index) => {
    return { label: String(index + 1), value: String(index + 1) };
  });
export const FREQUENCY_OPTIONS: SelectOptionsArray = [
  { label: 'All', value: 'null' },
].concat(FREQUENCY_VALUES);

export const DEFAULT_FILTERS = {
  title: '',
  type: '',
  responseType: '',
  active: null,
  advanced: null,
  frequency: '',
};
