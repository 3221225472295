import { useStore } from '../../../lib/store';
import { useEffect, useState } from 'react';
import { Modal } from '../../modals/Modal';
import { ModalMode } from '../../layouts/ModalLayout';
import { MainLayout } from '../../layouts/MainLayout';
import { shallow } from 'zustand/shallow';
import { showToast } from 'lib/toast';
import { PaginationFooter } from 'components/common/PaginationFooter/PaginationFooter';
import { createFeedbackService } from 'lib/services/feedbackService';
import { FeedbackTable } from 'components/tables/FeedbackTable';
import { FeedbackTableRow } from 'lib/types/tables';
import { AxiosError } from 'axios';
import { FeedbackInfoModal } from 'components/modals/FeedbackInfoModal';
import { FeedbackFiltersPopUp } from 'components/modals/FeedbackFiltersPopUp';
import { HiAdjustments, HiSearch } from 'react-icons/hi';
import classNames from 'classnames';
import { FeedbackFilters } from 'lib/types/entities';
import { FeedbackSearchPopUp } from 'components/modals/FeedbackSearchPopUp';

const { getFeedbacks, deleteFeedbackById } = createFeedbackService();

export const FeedbacksPage = () => {
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] =
    useState<boolean>(false);
  const [isSearchDropdownVisible, setIsSearchDropdownVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<number>(0);
  const [currentFilterParams, setCurrentFilterParams] =
    useState<FeedbackFilters>({
      startDate: null,
      endDate: null,
    });
  const [selectedFeedbackId, setSelectedFeedbackId] = useState<string>('');
  const [isDetailVisible, setIsDetailsVisible] = useState<boolean>(false);
  const [currentPaginationParams, setCurrentPaginationParams] = useState({
    page: 1,
    itemsPerPage: 30,
    totalItems: 0,
  });
  const { updateFeedbackTableData } = useStore(
    (state) => ({
      updateFeedbackTableData: state.updateFeedbackTableData,
    }),
    shallow,
  );
  const updateCurrentFilters = (filters: FeedbackFilters) => {
    setCurrentFilterParams(filters);
  };
  const getFeedbacksRequest = async (newPage?: number) => {
    try {
      const feedbacksResponse = await getFeedbacks({
        params: {
          page: newPage || currentPaginationParams.page,
          itemsPerPage: currentPaginationParams.itemsPerPage,
          cancellationDate:
            currentFilterParams.endDate && currentFilterParams.startDate
              ? {
                  before: currentFilterParams.endDate.toISOString(),
                  after: currentFilterParams.startDate.toISOString(),
                }
              : undefined,
        },
      });
      const feedbackData: FeedbackTableRow[] = feedbacksResponse?.data?.[
        'hydra:member'
      ].map(({ user, cancellationDate, ...otherItemFields }) => ({
        user: user || '-',
        cancellationDate,
        id: otherItemFields['@id'],
      }));

      setCurrentPaginationParams({
        ...currentPaginationParams,
        page: newPage || currentPaginationParams.page,
        totalItems: feedbacksResponse?.data?.['hydra:totalItems'],
      });
      updateFeedbackTableData(feedbackData);
    } catch (error) {
      showToast({
        type: 'error',
        title: `Error`,
        message: 'Cannot access feedbacks',
      });
    }
  };
  const onFeedbackDelete = async (deletingId: string) => {
    try {
      await deleteFeedbackById(
        deletingId.replace('/user_subscription_cancellations/', ''),
      );

      showToast({
        type: 'success',
        title: `Delete was successful`,
        message: 'Feedback has been successfully deleted!',
      });

      closeDetailModal();
      getFeedbacksRequest();
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.status === 422) {
        showToast({
          type: 'error',
          title: 'This feedback cannot be deleted!',
          message: 'Blocked by server.',
        });
        return;
      }

      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot delete feedback',
      });
    }
  };

  const closeDetailModal = () => {
    setIsDetailsVisible(false);
  };

  const openModalAndSetId = (value: string) => {
    setSelectedFeedbackId(value);
    setIsDetailsVisible(true);
  };

  useEffect(() => {
    getFeedbacksRequest();

    const activeFilterCount = Object.values({
      ...currentFilterParams,
      startDate: null,
    }).reduce((prevValue, value) => {
      return value ? prevValue + 1 : prevValue;
    }, 0);

    setActiveFilters(activeFilterCount);
  }, [currentFilterParams]);

  return (
    <MainLayout>
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-5 pb-4 px-4 border-b border-gray-200">
          <p className="text-gray-900 text-xl text-left"> User Cancellations</p>
          <div className="flex text-gray-500 relative">
            <button
              className="border border-gray-100 rounded p-2 flex items-center test-sm mr-4"
              onClick={() =>
                setIsSearchDropdownVisible(!isSearchDropdownVisible)
              }
            >
              <HiSearch className="fill-gray-500 mx-1" />
              Search
            </button>
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center test-sm',
                {
                  'bg-gray-100 text-black':
                    isFilterDropdownVisible || activeFilters > 0,
                },
              )}
              onClick={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
            >
              <HiAdjustments
                className={classNames('mx-1', {
                  'fill-gray-500': activeFilters === 0,
                  'fill-black text-black font-bold':
                    isFilterDropdownVisible || activeFilters > 0,
                })}
              />
              Filters {activeFilters ? `(${activeFilters})` : ''}
            </button>
            <FeedbackFiltersPopUp
              isVisible={isFilterDropdownVisible}
              onClose={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
              onChangeFilters={updateCurrentFilters}
            />
            <FeedbackSearchPopUp
              isVisible={isSearchDropdownVisible}
              onSearchItemClick={openModalAndSetId}
            />
          </div>
        </div>

        <FeedbackTable onItemClick={openModalAndSetId} />
        <PaginationFooter
          itemsPerPage={currentPaginationParams.itemsPerPage}
          currentPage={currentPaginationParams.page}
          totalItems={currentPaginationParams.totalItems}
          onPageClick={(newPage) => getFeedbacksRequest(newPage)}
        />
      </div>
      <Modal mode={ModalMode.right} isModalVisible={isDetailVisible}>
        <FeedbackInfoModal
          feedbackId={selectedFeedbackId}
          closeModal={closeDetailModal}
          onDelete={onFeedbackDelete}
        />
      </Modal>
    </MainLayout>
  );
};
