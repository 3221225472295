import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { TextInput } from 'components/common/TextInput';
import usePushManagementDraft from 'lib/hooks/usePushManagementDraft';
import { CustomSelect } from 'components/common/CustomSelect';

const timeList = [
  '12:00',
  '12:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
];

const timeSelectValues = [
  ...timeList.map((time) => ({ value: `${time} AM`, label: `${time} AM` })),
  ...timeList.map((time) => ({ value: `${time} PM`, label: `${time} PM` })),
];

export const TabDraft = () => {
  const {
    onSaveNotification,
    control,
    formState,
    fields,
    watchFields,
    isLoading,
  } = usePushManagementDraft();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div className="p-4">
        {fields &&
          fields.map(({ id, type, days }, index) => {
            const isSpecificDay = watchFields[index]?.specificDay;

            return (
              <div className="pb-4 mb-4 border-b border-gray-200" key={id}>
                <p className="text-lg mb-4 px-2">{type}</p>
                <div className="flex">
                  <div className="w-1/4">
                    <TextInput
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                      errorStyle="text-sm text-red-400 absolute -bottom-5"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      name={`pushNotifications[${index}].dayTitle`}
                      label="Push title"
                      control={control}
                      isDisabled={isSpecificDay}
                    />
                  </div>
                  <div className="w-1/2">
                    <TextInput
                      labelStyle="block text-sm text-gray-400 mb-2"
                      inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                      errorStyle="text-sm text-red-400 absolute -bottom-5"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      name={`pushNotifications[${index}].dayMessage`}
                      label="Push message"
                      control={control}
                      isDisabled={isSpecificDay}
                    />
                  </div>
                  <div className="w-52">
                    <CustomSelect
                      title="Push time"
                      titleStyle="block mb-2"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      name={`pushNotifications[${index}].deliveryTime`}
                      control={control}
                      options={timeSelectValues}
                      isDisabled={isSpecificDay}
                    />
                  </div>
                  <div className="w-52">
                    <CustomSelect
                      title="Day Specific"
                      titleStyle="block mb-2"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      name={`pushNotifications[${index}].specificDay`}
                      control={control}
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                      ]}
                    />
                  </div>
                  <div className="w-52">
                    <CustomSelect
                      title="Active"
                      titleStyle="block mb-2"
                      controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                      name={`pushNotifications[${index}].active`}
                      control={control}
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                      ]}
                      isDisabled={isSpecificDay}
                    />
                  </div>
                  <div className="w-52">
                    {formState.dirtyFields.pushNotifications?.[index] && (
                      <div className="w-full relative p-2 bg-blue-30 mb-4 w-full relative">
                        <button
                          type="button"
                          className="mt-[27px] bg-blue-500 text-white rounded py-2 px-4 items-center h-[46px] w-full text-center"
                          disabled={!formState.isValid}
                          onClick={() => onSaveNotification(index)}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {isSpecificDay &&
                    days?.map((day, dayIndex) => (
                      <div className="flex" key={day.id}>
                        <div className="w-1/4">
                          <TextInput
                            labelStyle="block text-sm text-gray-400 mb-2"
                            inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                            errorStyle="text-sm text-red-400 absolute -bottom-3"
                            controlStyle="p-2 bg-blue-30 w-full relative"
                            name={`pushNotifications[${index}].days[${dayIndex}].specificDayTitle`}
                            label={`${day.day?.toUpperCase()} Push title`}
                            control={control}
                          />
                        </div>
                        <div className="w-1/2">
                          <TextInput
                            labelStyle="block text-sm text-gray-400 mb-2"
                            inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                            errorStyle="text-sm text-red-400 absolute -bottom-2"
                            controlStyle="p-2 bg-blue-30 w-full relative"
                            name={`pushNotifications[${index}].days[${dayIndex}].specificDayMessage`}
                            label={`${day.day?.toUpperCase()} Push message`}
                            control={control}
                          />
                        </div>
                        <div className="w-52">
                          <CustomSelect
                            title="Push time"
                            titleStyle="block mb-2"
                            controlStyle="p-2 bg-blue-30 w-full relative"
                            name={`pushNotifications[${index}].days[${dayIndex}].deliveryTime`}
                            control={control}
                            options={timeSelectValues}
                          />
                        </div>
                        <div className="w-52" />
                        <div className="w-52">
                          <CustomSelect
                            title="Active"
                            titleStyle="block mb-2"
                            controlStyle="p-2 bg-blue-30 w-full relative"
                            name={`pushNotifications[${index}].days[${dayIndex}].active`}
                            control={control}
                            options={[
                              { value: true, label: 'Yes' },
                              { value: false, label: 'No' },
                            ]}
                          />
                        </div>
                        <div className="w-52" />
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
