type FeedbackInfoListProps = {
  infoFields: ReadonlyArray<{
    label: string;
    value: number | string | undefined;
  }>;
};

export const FeedbackInfoList: React.FC<FeedbackInfoListProps> = ({
  infoFields,
}) => {
  return (
    <div className="flex flex-col px-5 mt-16">
      {infoFields.map(({ label, value }) => (
        <div key={label} className="flex text-sm w-full mb-3 items-center">
          <p className="text-gray-500 w-36 text-md">{label}</p>
          <p className="text-gray-800 font-medium py-1 px-2">{value || '-'}</p>
        </div>
      ))}
    </div>
  );
};
