import { RemoteConfigSchema } from 'components/pages/RemoteConfigPage';
import api from './api';

export type SupportRequest = {
  message: string;
  email: string;
};
export type GetConfigResponse = {
  id: string;
  iosLatest: string;
  iosMinNotBlocked: string;
  androidLatest: string;
  androidMinNotBlocked: string;
};

export type GetRemoteConfigResponse = {
  appVersions: GetConfigResponse;
};

export const configService = () => {
  const getConfigRequest = async () => {
    return api({}).get<GetConfigResponse>(`/configs`, {});
  };
  const getRemoteConfig = async () => {
    return api({
      headers: { 'Content-type': 'application/ld+json' },
    }).get<GetRemoteConfigResponse>(`/remote_config`, {});
  };
  const putConfig = async (configId: string, data: RemoteConfigSchema) => {
    return api({
      headers: { 'Content-type': 'application/ld+json' },
    }).put<GetConfigResponse>(`/configs/${configId}`, data);
  };

  return { putConfig, getRemoteConfig, getConfigRequest };
};
