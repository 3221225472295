import React from 'react';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export enum ModalMode {
  center = 'CENTER',
  right = 'RIGHT',
}

export type ModalLayoutProps = PropsWithChildren<{
  readonly mode?: ModalMode;
}>;

export const ModalLayout: React.FC<ModalLayoutProps> = ({ children, mode }) => {
  const isCentered = mode === ModalMode.center;

  return (
    <div
      className={classNames(
        'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full inset-0 h-full bg-gray-200 bg-opacity-50',
        {
          'flex items-center justify-center': isCentered,
          'flex justify-end': !isCentered,
        },
      )}
    >
      <div
        className={classNames('bg-white', {
          'min-w-[500px]': isCentered,
        })}
      >
        {children}
      </div>
    </div>
  );
};
