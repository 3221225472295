import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from 'components/common/PrivateRoutes';
import { FaqPage } from 'components/pages/FaqPage';
import { UsersPage } from 'components/pages/UsersPage';
import { LoginPage } from 'components/pages/LoginPage';
import { FeedbacksPage } from 'components/pages/FeedbacksPage';
import { MediaFilesPage } from 'components/pages/MediaFilesPage';
import { RemoteConfigPage } from 'components/pages/RemoteConfigPage';
import { TabManagementPage } from 'components/pages/TabManagementPage';
import { ForgotPasswordPage } from 'components/pages/ForgotPasswordPage';
import { PushManagementPage } from 'components/pages/PushManagementPage';
import { DraftManagementPage } from 'components/pages/DraftManagementPage';
import { ContentManagementPage } from 'components/pages/ContentManagementPage';

const App: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-200 font-[Helvetica Neue] relative">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/users" element={<UsersPage />} />
            <Route
              path="/contentManagement"
              element={<ContentManagementPage />}
            />
            <Route path="/tabManagement" element={<TabManagementPage />} />
            <Route path="/mediaLibrary" element={<MediaFilesPage />} />
            <Route path="/draftManagement" element={<DraftManagementPage />} />
            <Route path="/pushManagement" element={<PushManagementPage />} />
            <Route path="/feedbacks" element={<FeedbacksPage />} />
            <Route path="/config" element={<RemoteConfigPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
