import { FeedbackQuestion } from 'lib/types/entities';
import api, { PagingParams } from './api';

export type FeedbackDetails = {
  '@id': string;
  user: string;
  cancellationDate: string;
  details: Array<FeedbackQuestion & { '@id': string }>;
};
export type GetFeedbackItem = {
  '@id': string;
  user: string;
  cancellationDate: string;
};
export type GetFeedbackByIdResponse = {
  details: FeedbackQuestion[];
  '@id': string;
  user: string;
  cancellationDate: string;
};

export type GetFeedbackResponse = {
  'hydra:member': GetFeedbackItem[];
  'hydra:totalItems': number;
};
export type GetFeedbackRequestParams = {
  params: PagingParams & FeedbackFilters;
};
export type FeedbackFilters = {
  'user.firebaseUser'?: string;
  'user.email'?: string;
  cancellationDate?: string | { before?: string; after?: string };
};
export const createFeedbackService = () => {
  const getFeedbacks = async ({
    params = { page: 1, itemsPerPage: 30 },
  }: GetFeedbackRequestParams) => {
    return await api({}).get<GetFeedbackResponse>(
      '/user_subscription_cancellations',
      { params },
    );
  };

  const getFeedbackById = async (fileId: string) => {
    return await api({}).get<FeedbackDetails>(
      `/user_subscription_cancellations/${fileId}`,
    );
  };

  const deleteFeedbackById = async (fileId: string) => {
    return await api({}).delete(`/user_subscription_cancellations/${fileId}`);
  };

  return {
    deleteFeedbackById,
    getFeedbackById,
    getFeedbacks,
  };
};
