import classNames from 'classnames';
import { formatDate } from 'lib/utils/formatDate';
import { SyntheticEvent, useState } from 'react';
import DatePicker from 'react-datepicker';
import { HiChevronDown, HiOutlineCalendar } from 'react-icons/hi';
import 'react-datepicker/dist/react-datepicker.css';

type FeedbackFiltersPopUpProps = {
  isVisible: boolean;
  onClose: () => void;
  onChangeFilters: (filters: {
    startDate: Date | null;
    endDate: Date | null;
  }) => void;
};

export const FeedbackFiltersPopUp: React.FC<FeedbackFiltersPopUpProps> = ({
  isVisible,
  onClose,
  onChangeFilters,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChange = (dates: (Date | null)[]) => {
    const [start, end] = dates;
    if (start?.getTime() === end?.getTime()) {
      start?.setHours(0, 0, 0, 0);
      end?.setHours(23, 59, 59, 0);
      setStartDate(start);
      setEndDate(end);
      if (end) {
        setIsDatePickerOpen(false);
      }
      return;
    }
    start?.setHours(0, 0, 0, 0);
    end?.setHours(0, 0, 0, 0);
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setIsDatePickerOpen(false);
    }
  };
  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  const handleResetFilters = () => {
    onChangeFilters({
      startDate: null,
      endDate: null,
    });
    setStartDate(new Date());
    setEndDate(null);
    setIsDatePickerOpen(false);
    onClose();
  };
  const handleApplyFilters = () => {
    onChangeFilters({
      startDate,
      endDate,
    });
    setIsDatePickerOpen(false);
    onClose();
  };

  return (
    <div
      id="dropdown"
      className={classNames(
        'absolute z-10 w-[400px] bg-white rounded shadow top-14 right-24 p-4',
        { hidden: !isVisible },
      )}
    >
      <p className="text-black font-medium pb-4">All Filters</p>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs mb-4">
        <div className="flex items-center">
          <HiOutlineCalendar className="w-4 h-4 text-black" />
          <p className="text-black font-bold px-2 text-sm">Cancellation Date</p>
        </div>
        <div
          className="w-52 bg-white flex items-center justify-end mr-1 cursor-pointer"
          onClick={handleClick}
        >
          <p className="px-2">
            {endDate && startDate
              ? formatDate(startDate) + ' - ' + formatDate(endDate)
              : 'All'}
          </p>
          <HiChevronDown className="w-4 h-4" />
        </div>
      </div>
      <button
        type="button"
        className="w-full text-gray-500 border border-gray-200 flex text-center justify-center rounded py-1 text-sm"
        onClick={handleResetFilters}
      >
        Default settings
      </button>
      <button
        type="button"
        className="w-full bg-blue-500 text-white border border-gray-200 flex text-center justify-center rounded py-1 my-2 text-sm"
        onClick={handleApplyFilters}
      >
        Apply
      </button>
      {isDatePickerOpen && (
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          maxDate={new Date()}
          showYearDropdown
          showMonthDropdown
        />
      )}
    </div>
  );
};
