import { HiAdjustments, HiTable } from 'react-icons/hi';
import { useStore } from '../../../lib/store';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from '../../modals/Modal';
import { ModalMode } from '../../layouts/ModalLayout';
import { MainLayout } from '../../layouts/MainLayout';
import { shallow } from 'zustand/shallow';
import { showToast } from 'lib/toast';
import { PaginationFooter } from 'components/common/PaginationFooter/PaginationFooter';
import { MediaFilesTable } from 'components/tables/MediaFilesTable';
import {
  GetMediaFileResponse,
  MediaFile,
  mediaFilesService,
} from 'lib/services/mediaFilesServices';
import { MediaFileInfoModal } from 'components/modals/MediaFileInfoModal';
import { CreateMediaFileModal } from 'components/modals/CreateMediaFileModal';
import { MediaFilesFiltersPopUp } from 'components/modals/MediaFilesFiltersPopUp';
import classNames from 'classnames';
import { AxiosError } from 'axios';
import { TableSorting } from 'lib/types/entities';

const { getFiles, deleteFileById, patchFileById } = mediaFilesService();

export const MediaFilesPage = () => {
  const [isDetailVisible, setIsDetailsVisible] = useState<boolean>(false);
  const [isCreateFileModalVisible, setIsCreateFileModaVisible] =
    useState<boolean>(false);
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<number>(0);
  const [currentFilterParams, setCurrentFilterParams] = useState({
    type: '',
  });
  const [currentPaginationParams, setCurrentPaginationParams] = useState({
    page: 1,
    itemsPerPage: 30,
    totalItems: 0,
  });
  const [selectedFileId, setSelectedFileId] = useState<string>('');
  const { updateMediaFilesTableData } = useStore(
    (state) => ({
      updateMediaFilesTableData: state.updateMediaFilesTableData,
    }),
    shallow,
  );

  const getFilesRequest = async (newPage?: number) => {
    try {
      const mediaFilesResponse = await getFiles({
        params: {
          page: newPage || currentPaginationParams.page,
          itemsPerPage: currentPaginationParams.itemsPerPage,
          type: currentFilterParams?.type
            ? currentFilterParams?.type
            : undefined,
          order: currentSortingParams,
        },
      });
      const mediaFilesData: GetMediaFileResponse = mediaFilesResponse?.data;
      const data = mediaFilesData['hydra:member'].map((file: MediaFile) => {
        return {
          id: file['@id'],
          size: file.fileSize,
          title: file.title,
          type: file.type,
          createdAt: file.createdAt,
          audioDuration: file.audioDuration,
          durationText: file.durationText,
        };
      });

      setCurrentPaginationParams({
        ...currentPaginationParams,
        page: newPage || currentPaginationParams.page,
        totalItems: mediaFilesData['hydra:totalItems'],
      });
      updateMediaFilesTableData(data);
    } catch (error) {
      showToast({
        type: 'error',
        title: `Error`,
        message: 'Cannot access files',
      });
    }
  };
  const onFileDelete = async (deletingId: string) => {
    try {
      await deleteFileById(
        deletingId.replace('/files/', '').replace('/download', ''),
      );

      showToast({
        type: 'success',
        title: `Delete was successful`,
        message: 'The file has been successfully deleted!',
      });

      closeDetailModal();
      getFilesRequest();
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.status === 422) {
        showToast({
          type: 'error',
          title: 'This file cannot be deleted!',
          message: 'File is used.',
        });
        return;
      }

      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot delete file',
      });
    }
  };

  const onFileUpdate = async (updateFile: MediaFile) => {
    try {
      await patchFileById(
        selectedFileId.replace('/files/', '').replace('/download', ''),
        updateFile,
      );
      await getFilesRequest(currentPaginationParams.page);
      showToast({
        type: 'success',
        title: `Update was successful`,
        message: 'The file title has been successfully updated!',
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot update file',
      });
    }
  };
  const closeDetailModal = () => {
    setIsDetailsVisible(false);
  };
  const onOpenCreateMediaFileModal = () => {
    setIsCreateFileModaVisible(true);
  };
  const closeCreateMediaFileModal = () => {
    setIsCreateFileModaVisible(false);
  };

  const openModalAndSetId = useCallback((value: string) => {
    setSelectedFileId(value);
    setIsDetailsVisible(true);
  }, []);

  const updateCurrentFilters = (filters: { type: string }) => {
    setCurrentFilterParams(filters);
  };

  const [currentSortingParams, setCurrentSortingParams] =
    useState<TableSorting>({});

  useEffect(() => {
    getFilesRequest();

    const activeFilterCount = Object.values({
      ...currentFilterParams,
      startDate: null,
    }).reduce((prevValue, value) => {
      return value ? prevValue + 1 : prevValue;
    }, 0);

    setActiveFilters(activeFilterCount);
  }, [currentFilterParams, currentSortingParams]);

  const onSortClick = useCallback((sorting: TableSorting) => {
    setCurrentSortingParams(sorting);
  }, []);

  return (
    <MainLayout>
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-5 pb-4 px-4 border-b border-gray-200">
          <p className="text-gray-900 text-xl text-left"> Media Files</p>
          <div className="flex text-gray-500 relative">
            <button
              className="border border-gray-100 rounded py-2 px-4 flex items-center text-sm mr-4"
              onClick={onOpenCreateMediaFileModal}
            >
              <HiTable className="fill-gray-500 mx-1" />
              Add File
            </button>
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center text-sm',
                {
                  'bg-gray-100 text-black':
                    isFilterDropdownVisible || activeFilters > 0,
                },
              )}
              onClick={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
            >
              <HiAdjustments
                className={classNames('mx-1', {
                  'fill-gray-500': activeFilters === 0,
                  'fill-black text-black font-bold':
                    isFilterDropdownVisible || activeFilters > 0,
                })}
              />
              Filters {activeFilters ? `(${activeFilters})` : ''}
            </button>
            <MediaFilesFiltersPopUp
              isVisible={isFilterDropdownVisible}
              onClose={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
              onChangeFilters={updateCurrentFilters}
            />
          </div>
        </div>
        <MediaFilesTable
          onItemClick={openModalAndSetId}
          onSortClick={onSortClick}
          currentSorting={currentSortingParams}
        />
        <PaginationFooter
          itemsPerPage={currentPaginationParams.itemsPerPage}
          currentPage={currentPaginationParams.page}
          totalItems={currentPaginationParams.totalItems}
          onPageClick={(newPage) => getFilesRequest(newPage)}
        />
      </div>
      <Modal mode={ModalMode.right} isModalVisible={isDetailVisible}>
        <MediaFileInfoModal
          fileId={selectedFileId}
          closeModal={closeDetailModal}
          onDelete={onFileDelete}
          onUpdate={onFileUpdate}
        />
      </Modal>
      <Modal mode={ModalMode.center} isModalVisible={isCreateFileModalVisible}>
        <CreateMediaFileModal
          closeModal={closeCreateMediaFileModal}
          onCreate={getFilesRequest}
        />
      </Modal>
    </MainLayout>
  );
};
