import React, { memo, useEffect, useState } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { shallow } from 'zustand/shallow';
import { UsersTableRow } from '../../../lib/types/tables';
import { columnList } from './Data';
import classNames from 'classnames';
import { formatFirebaseProvider } from 'lib/utils/formatFirebaseProvider';
import { formatDate } from 'lib/utils/formatDate';
import { Tooltip } from 'react-tooltip';
import { TableSorting } from 'lib/types/entities';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';
import { formatColumnName } from 'lib/utils/userTableHelper';

export type UserTableProps = {
  onItemClick: (value: string) => void;
  onSortClick: (value: TableSorting) => void;
};

export const UsersTable: React.FC<UserTableProps> = memo(
  ({ onItemClick, onSortClick }) => {
    const [currentSorting, setCurrentSorting] = useState<TableSorting | null>(
      null,
    );
    const { usersTableData } = useStore(
      (state) => ({
        usersTableData: state.usersTableData,
      }),
      shallow,
    );
    const data = React.useMemo<UsersTableRow[]>(
      () =>
        usersTableData.map(
          ({
            id,
            roles,
            email,
            phone,
            provider,
            userProfile,
            createdAt,
            isTester,
          }) => {
            return {
              id: id || '-',
              email: email || '-',
              isTester: isTester ? 'Active' : 'Not Active',
              name: userProfile?.firstName
                ? userProfile?.firstName + ' ' + userProfile?.lastName
                : '-',
              roleId: roles ? roles[0] : '-',
              phone: phone || '-',
              provider: provider ? formatFirebaseProvider(provider) : '-',
              subscriptionType: userProfile?.subscriptionType || '-',
              subscriptionStatus: userProfile?.subscriptionStatus || '-',
              paymentStatus: userProfile?.paymentStatus || '-',
              createdAt: createdAt ? formatDate(new Date(createdAt)) : '-',
              subscriptionDate: userProfile?.subscriptionDate
                ? formatDate(new Date(userProfile?.subscriptionDate))
                : '-',
            };
          },
        ),
      [usersTableData],
    );
    const columns = React.useMemo<Column<UsersTableRow>[]>(
      () => columnList,
      [],
    );
    const initialState = { hiddenColumns: ['roleId', 'isTester'] };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data, initialState });

    const onColumnClick = (column: string) => {
      const actualColumnName = formatColumnName(column);

      if (currentSorting?.[actualColumnName] === 'desc') {
        setCurrentSorting({
          [actualColumnName]: 'asc',
        });
      } else {
        setCurrentSorting({
          [actualColumnName]: 'desc',
        });
      }
    };

    useEffect(() => {
      currentSorting && onSortClick(currentSorting);
    }, [currentSorting]);

    return (
      <table {...getTableProps()} className="w-full text-xs">
        <thead className="h-11 border-b border-gray-200 text-left ">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-gray-400 font-medium"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={classNames('h-10 px-4', {
                    'min-w-[100px]':
                      column.id === 'phone' ||
                      column.id === 'name' ||
                      column.id === 'email',
                    'w-[150px]': column.id === 'phone',
                    'w-[130px]':
                      column.id === 'createdAt' ||
                      column.id === 'subscriptionDate' ||
                      column.id === 'subscriptionStatus' ||
                      column.id === 'paymentStatus' ||
                      column.id === 'provider' ||
                      column.id === 'subscriptionType',
                  })}
                >
                  <div
                    onClick={() => {
                      onColumnClick(column.id);
                    }}
                    className={classNames(
                      'flex justify-start items-center cursor-pointer',
                      {
                        'text-blue-500':
                          currentSorting?.[formatColumnName(column.id)],
                      },
                    )}
                  >
                    {column.render('Header')}
                    <p className="mx-1 h-4 relative">
                      <GoTriangleUp
                        className={classNames('w-2 h-2 top-[1px] absolute', {
                          hidden: currentSorting?.[formatColumnName(column.id)],
                        })}
                      />
                      <GoTriangleDown
                        className={classNames('w-2 h-2 bottom-[1px] absolute', {
                          hidden: currentSorting?.[formatColumnName(column.id)],
                        })}
                      />
                      <HiArrowDown
                        className={classNames('w-4 h-4 bottom-[1px] absolute', {
                          hidden:
                            currentSorting?.[formatColumnName(column.id)] !==
                            'desc',
                        })}
                      />
                      <HiArrowUp
                        className={classNames('w-4 h-4 bottom-[1px] absolute', {
                          hidden:
                            currentSorting?.[formatColumnName(column.id)] !==
                            'asc',
                        })}
                      />
                    </p>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="h-10 text-left border-b hover:bg-blue-100"
                onClick={(event) => {
                  const target = event.target as Element;
                  if (
                    target.tagName !== 'INPUT' &&
                    target.tagName !== 'BUTTON'
                  ) {
                    onItemClick(row.values.id);
                  }
                }}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={classNames('px-1 mr-auto max-w-[10px]', {
                      capitalize:
                        cell.column.id === 'paymentStatus' ||
                        cell.column.id === 'subscriptionStatus' ||
                        cell.column.id === 'subscriptionType',
                    })}
                  >
                    <div className="h-full overflow-x-hidden text-ellipsis">
                      {cell.column.id === 'name' &&
                      cell.row.values.isTester === 'Active' ? (
                        <div className="relative my-1 max-w-fit">
                          {cell.render('Cell')}
                          <p
                            data-tooltip-id={'tooltip' + cell.row.id}
                            data-tooltip-content="This user is a tester"
                            data-tooltip-place="right"
                            className="absolute inline-flex items-center justify-center w-4 h-4 text-[9px] font-bold text-white bg-blue-500 border-2 border-white rounded-full -top-1 -right-5 dark:border-gray-900"
                          >
                            T
                          </p>
                          <Tooltip
                            id={'tooltip' + cell.row.id}
                            style={{ padding: '1px 4px' }}
                          />
                        </div>
                      ) : (
                        cell.render('Cell')
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);
