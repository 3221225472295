import { useState } from 'react';
import classNames from 'classnames';
import { MainLayout } from 'components/layouts/MainLayout';
import { TabLive } from './TabLive/TabLive';
import { TabDraft } from './TabDraft/TabDraft';
import { TabCompare } from './TabCompare/TabCompare';
import { AxiosError } from 'axios';
import { BackendError } from '../../../lib/types/entities';
import { showToast } from '../../../lib/toast';
import { pushConfigDraftsService } from '../../../lib/services/pushConfigDraftsService';
import { LoadingIndicator } from '../../common/LoadingIndicator';

const pushManagementTabs: { [x: string]: string } = {
  LIVE: 'Live',
  DRAFT: 'Draft',
  COMPARE: 'Compare',
};

const { approvePushConfigDraftsRequest, resetPushConfigDraftsRequest } =
  pushConfigDraftsService();

export const PushManagementPage = () => {
  const [currentTab, setCurrentTab] = useState(pushManagementTabs.LIVE);
  const [isLoading, setIsLoading] = useState(false);

  const onApprove = async () => {
    setIsLoading(true);

    try {
      await approvePushConfigDraftsRequest();

      setCurrentTab(pushManagementTabs.LIVE);
    } catch (e) {
      const error = e as AxiosError & { data: BackendError };
      console.error(error);

      if (error.data?.['hydra:description']) {
        showToast({
          type: 'error',
          title: `Error`,
          message: error.data['hydra:description'],
        });
      } else {
        showToast({
          type: 'error',
          title: `Error`,
          message: 'Cannot approve push settings',
        });
      }
    }

    setIsLoading(false);
  };

  const onReset = async () => {
    setIsLoading(true);

    try {
      await resetPushConfigDraftsRequest();

      setCurrentTab(pushManagementTabs.LIVE);
    } catch (e) {
      const error = e as AxiosError & { data: BackendError };
      console.error(error);

      if (error.data?.['hydra:description']) {
        showToast({
          type: 'error',
          title: `Error`,
          message: error.data['hydra:description'],
        });
      } else {
        showToast({
          type: 'error',
          title: `Error`,
          message: 'Cannot reset push settings',
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <MainLayout>
      <div className="flex flex-col min-h-full">
        <div className="flex items-center justify-between pt-5 pb-4 px-4 border-b border-gray-200">
          <p className="text-gray-900 text-xl text-left">Push Management </p>
        </div>

        <div className="border-b border-gray-200 px-4 flex justify-between items-center">
          <ul className="flex gap-4 text-xl">
            {Object.keys(pushManagementTabs).map((tab) => (
              <li key={tab}>
                <button
                  onClick={() => {
                    setCurrentTab(pushManagementTabs[tab]);
                  }}
                  className={classNames(
                    'inline-block mt-5 pb-4 rounded-t-lg border-b hover:text-gray-600 hover:border-gray-300',
                    {
                      'text-gray-700 border-black':
                        currentTab === pushManagementTabs[tab],
                      'border-transparent':
                        currentTab !== pushManagementTabs[tab],
                    },
                  )}
                >
                  <p className="flex">{pushManagementTabs[tab]}</p>
                </button>
              </li>
            ))}
          </ul>
          {currentTab !== pushManagementTabs.LIVE && (
            <div className="">
              <button
                type="button"
                className="bg-blue-500 text-white px-10 py-2 rounded mx-4"
                onClick={() => onApprove()}
                disabled={isLoading}
              >
                Approve
              </button>
              <button
                type="button"
                className="bg-blue-500 text-white px-10 py-2 rounded mx-4"
                onClick={() => onReset()}
                disabled={isLoading}
              >
                Reset
              </button>
            </div>
          )}
        </div>

        {currentTab === pushManagementTabs.LIVE && <TabLive />}
        {currentTab === pushManagementTabs.DRAFT && <TabDraft />}
        {currentTab === pushManagementTabs.COMPARE && <TabCompare />}
      </div>
    </MainLayout>
  );
};
