import { ReadonlyInput } from 'components/common/ReadonlyInput';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import usePushManagementLive from 'lib/hooks/usePushManagementLive';

export const TabLive = () => {
  const { pushConfig, isLoading } = usePushManagementLive();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="p-4">
      {pushConfig.map(
        (
          {
            type,
            deliveryTime,
            days,
            specificDay: isSpecificDay,
            dayTitle,
            dayMessage,
            active,
          },
          index,
        ) => {
          return (
            <div className="pb-4 mb-4 border-b border-gray-200" key={index}>
              <p className="text-lg mb-4 px-2">{type}</p>
              <div className="flex">
                <div className="w-1/4">
                  <ReadonlyInput
                    label="Push title"
                    value={dayTitle}
                    isDisabled={isSpecificDay}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                </div>
                <div className="w-1/2">
                  <ReadonlyInput
                    label="Push message"
                    value={dayMessage}
                    isDisabled={isSpecificDay}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                </div>
                <div className="w-52">
                  <ReadonlyInput
                    label="Push time"
                    value={deliveryTime}
                    isDisabled={isSpecificDay}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                </div>
                <div className="w-52">
                  <ReadonlyInput
                    value={isSpecificDay ? 'Yes' : 'No'}
                    label="Day Specific"
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                </div>
                <div className="w-52">
                  <ReadonlyInput
                    value={active ? 'Yes' : 'No'}
                    label="Active"
                    isDisabled={isSpecificDay}
                    labelStyle="block text-sm text-gray-400 mb-2"
                    inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                    controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                  />
                </div>
                <div className="w-52" />
              </div>
              <div>
                {isSpecificDay &&
                  days?.map((day, dayIndex) => (
                    <div className="flex" key={day.id}>
                      <div className="w-1/4">
                        <ReadonlyInput
                          label={`${day.day?.toUpperCase()} Push title`}
                          value={day.specificDayTitle}
                          labelStyle="block text-sm text-gray-400 mb-2"
                          inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                          controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                        />
                      </div>
                      <div className="w-1/2">
                        <ReadonlyInput
                          label={`${day.day?.toUpperCase()} Push message`}
                          value={day.specificDayMessage}
                          labelStyle="block text-sm text-gray-400 mb-2"
                          inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                          controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                        />
                      </div>
                      <div className="w-52">
                        <ReadonlyInput
                          label="Push time"
                          value={day.deliveryTime}
                          labelStyle="block text-sm text-gray-400 mb-2"
                          inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                          controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                        />
                      </div>
                      <div className="w-52" />
                      <div className="w-52">
                        <ReadonlyInput
                          value={day.active ? 'Yes' : 'No'}
                          label="Active"
                          labelStyle="block text-sm text-gray-400 mb-2"
                          inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                          controlStyle="p-2 bg-blue-30 mb-4 w-full relative"
                        />
                      </div>
                      <div className="w-52" />
                    </div>
                  ))}
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
