import { NavLink } from 'react-router-dom';

const NavigationList = [
  {
    link: '/users',
    title: 'Users',
  },
  {
    link: '/contentManagement',
    title: 'Content Management',
  },
  {
    link: '/tabManagement',
    title: 'Tab Management',
  },
  {
    link: '/draftManagement',
    title: 'Draft Management',
  },
  {
    link: '/mediaLibrary',
    title: 'Media library',
  },
  {
    link: '/feedbacks',
    title: 'Feedbacks',
  },
  {
    link: '/config',
    title: 'Remote config',
  },
  {
    link: '/pushManagement',
    title: 'Push Management',
  },
];

export const Navigation = () => {
  return (
    <div className="flex items-center text-sm">
      <div className="px-5 border-r border-gray-200 min-w-[150px]">
        <img src="/logo.svg" alt="Happy tab logo" className="h-8" />
      </div>
      <div className="flex justify-between items-center px-4">
        {NavigationList.map((navigation) => (
          <NavLink
            key={navigation.title}
            to={navigation.link}
            className={({ isActive }) =>
              isActive
                ? 'flex items-center px-1 py-0.5 relative after:block after:h-[3px] after:bg-black after:rounded-full after:absolute after:-bottom-3 after:left-0 after:right-0'
                : 'flex items-center px-1 py-0.5 rounded'
            }
          >
            <p className="p-2 w-full whitespace-nowrap overflow-hidden first-letter:uppercase">
              {navigation.title}
            </p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
